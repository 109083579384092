export enum VenueQuickFilterValues {
  CAPACITY = "capacity",
  BEST_USED_FOR = "bestUsedFor",
  REGIONS = "regions",
}

export enum ListingCardType {
  SIMILAR = "similar",
  POPULAR = "popular",
}

export enum VendorQuickFilterValues {
  PRICING = "vendorPricing",
  TYPE = "vendorType",
  REGIONS = "regions",
}

export enum MobileListingModalState {
  HIDDEN = "hidden",
  CLOSED = "closed",
  DEFAULT = "default"
}
