import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { PageLayoutContext } from "@/src/component/layout/PageLayout";
import { ListingGrid, Map, resultsToCards } from "@/src/component/partial";
import { useApp, useCMS } from "@/src/component/provider";
import { FILTERS, POPULAR_LISTINGS_COUNT } from "@/src/const";
import { DEFAULT_LOCATION } from "@/src/const/map";
import { SearchFiltersType, useBreakPointDown, useNextNavigation } from "@/src/hook";
import { ActivityLogic, ListingLogic, SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { AppSearchFilterType, BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import { MobileListingModalState } from "@/src/type/component/Listing";
import { Log, URLHelper } from "@/src/util";
import type { MapLocationMarker } from "@/src/component/partial";
import type { AppContextType } from "@/src/component/provider";
import styles from "./Listing.module.scss";
import {
  DataTypeButtonGroup,
  DraggableListingView,
  MapButton,
  NoResults,
  PopularListings,
  QuickFilters,
} from "./component";

interface ListingProps {
  initialSearch: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  initialGeoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  initialQuery: BlaceV2Type.AzureSearchQueryType.Request;
  initialPerPage: number;
  initialPage: number;
}

type LastSearchQuery = {
  searchId?: string;
  searchTerm?: string;
  resultsPerPage: number;
  pageNumber: number;
  searchResults: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  query: BlaceV2Type.AzureSearchQueryType.Request;
  geoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  cards: ReactElement[];
  viewVariant: keyof typeof ListingDisplayVariant;
};

interface FilterTag {
  isMulti: boolean;
  title: string;
  data: string;
  dataKey: string;
  value: string;
  defaultValue?: number | string;
}

function Listing({
  initialSearch,
  initialGeoPoints,
  initialQuery,
  initialPerPage,
  initialPage,
}: ListingProps) {
  const router = useRouter();
  const isMobile = useBreakPointDown("md");
  const app = useApp();
  const cms = useCMS();
  const defaultListingType = BlaceV2Type.SearchTypes.Venue;
  const nextNavigation = useNextNavigation();
  const [viewVariant, setViewVariant] = useState<keyof typeof ListingDisplayVariant>(
    ListingDisplayVariant.full,
  );
  const [showMap, setShowMap] = useState<boolean>(false);
  const [listingType, setListingType] = useState(
    app.searchFilters?.filterState.dataType
      ? app.searchFilters?.filterState.dataType[0]
      : defaultListingType,
  );
  const [popularListings, setPopularListings] = useState<BlaceV2Type.SearchType.Search[]>([]);
  const [mapDrawerOpen, setMapDrawerOpen] = useState<boolean>(false);
  const [hoveredListingSlug, setHoveredListingSlug] = useState<string | undefined>(undefined);
  const [listingModalState, setListingModalState] = useState<MobileListingModalState>(
    MobileListingModalState.HIDDEN,
  );
  const [lastSearchQuery, setLastSearchQuery] = useState<LastSearchQuery>({
    searchId: app.searchFilters?.searchId,
    searchTerm: app.searchTerm,
    resultsPerPage: initialPerPage ?? 20,
    pageNumber: initialPage,
    searchResults: initialSearch,
    query: initialQuery,
    geoPoints: initialGeoPoints,
    viewVariant: viewVariant ?? ListingDisplayVariant.full,
    cards: resultsToCards({
      items: initialSearch.value,
      viewVariant,
      ads:
        (1 * 5) % 10 === 0
          ? [{ placement: 4 }, { placement: 11 }]
          : [{ placement: 6 }, { placement: 14 }],
      alwaysEager: false,
      onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
    }),
  });

  // //last city background
  // const [backgroundImageCity, setBackgroundImageCity] = useState<string | undefined>(
  //   app?.searchFilters?.filterState?.regions ? app?.searchFilters?.filterState?.regions : undefined,
  // );

  // const isCitySelected = !!backgroundImageCity;
  const pageLayoutContext = PageLayoutContext.usePageLayoutContext();

  useEffect(() => {
    // check if filtersState has dataType value
    const newListingType =
      app.searchFilters?.filterState.dataType && app.searchFilters?.filterState.dataType[0];
    // set the listingType to set tabs value and detect is map visible
    setListingType(newListingType || defaultListingType);
    setViewVariant(
      !isMobile && newListingType !== BlaceV2Type.SearchTypes.Vendor
        ? ListingDisplayVariant.map
        : ListingDisplayVariant.full,
    );

    if (newListingType) {
      setShowMap(newListingType !== BlaceV2Type.SearchTypes.Vendor);
    } else {
      setShowMap(true);
    }
  }, [app.searchFilters?.filterState.dataType, defaultListingType, isMobile]);

  useEffect(() => {
    // if listing type was changes - get new data. Needed to set "venue" as a default value when user clicks on logo
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    runSearchQuery(lastSearchQuery, app, parseInt(params.get("page") ?? "1", 10));
    setShowMap(listingType === BlaceV2Type.SearchTypes.Venue);
    setViewVariant(
      !isMobile && listingType !== BlaceV2Type.SearchTypes.Vendor
        ? ListingDisplayVariant.map
        : ListingDisplayVariant.full,
    );
    if (listingType === BlaceV2Type.SearchTypes.Vendor && isMobile) {
      setListingModalState(MobileListingModalState.HIDDEN);
    }
  }, [listingType, isMobile, app.searchFilters?.filterState]);

  /**
   * insert default filter values on the first render
   */
  const filters = app?.searchFilters;
  
  useEffect(() => {
    // to not replace filters with default if they are already included in the url
    if (router.query.dataType) {      
      return;
    }

    const getUserRegion = async () => {
      const userGeo = await ActivityLogic.getGeoLocation();

      if (userGeo && filters) {
        if (DEFAULT_LOCATION.LA.states.includes(userGeo?.state || "")) {
          filters.filterState.regions = BlaceV2Type.Regions.LA;
        }

        if (DEFAULT_LOCATION.NY.states.includes(userGeo?.state || "")) {
          filters.filterState.regions = BlaceV2Type.Regions.NY;
        }
      }

      insertDefaultFilters(filters);
    };

    getUserRegion();
  }, []);

  // it's not needed right now
  // todo: remove it after Jun'25 if will not be used
  // /**
  //  * clear the background if the route changes
  //  */
  // useEffect(() => {
  //   if (backgroundImageCity === app?.searchFilters?.filterState?.regions) {
  //     return;
  //   }
  //
  //   if (!backgroundImageCity && !app?.searchFilters?.filterState?.regions) {
  //     return;
  //   }
  //
  //   setBackgroundImageCity(app?.searchFilters?.filterState?.regions);
  // }, [app?.searchFilters?.filterState?.regions, backgroundImageCity]);

  useEffect(() => {
    const paramViewVariant = nextNavigation.getAndValidateInitialQueryParam(
      ListingDisplayVariant,
      "viewVariant",
    );
    if (paramViewVariant) {
      setViewVariant(paramViewVariant);
    }
    return () => {
      pageLayoutContext.setShowListingQuickFiltersInHeader(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    pageLayoutContext.setShowListingQuickFiltersInHeader(isMobile);
    setListingModalState(
      isMobile && listingType !== BlaceV2Type.SearchTypes.Vendor && false // TODO: unlock it after investigation or drag-and-drop refactor
        ? MobileListingModalState.DEFAULT
        : MobileListingModalState.HIDDEN,
    );
  }, [isMobile, listingType]);


  useEffect(() => {
    if (lastSearchQuery?.cards?.length) { // the popular listings should be shown only when no Listings cards found
      return;
    }

    const fetchPopularListings = async () => {
      const popularListingsData = await ListingLogic.getPopularListings(
        listingType,
        POPULAR_LISTINGS_COUNT,
        filters?.filterState.regions || undefined,
        URLHelper.urlGetParameter("searchSessionId") ?? initialQuery.sessionId
      );
      setPopularListings(popularListingsData);
    };

    fetchPopularListings();
  }, [lastSearchQuery?.cards?.length, listingType, filters?.filterState?.regions, initialQuery.sessionId]);

  /**
   * effects to control the map drawer when in mobile mode
   */
  useEffect(() => {
    if (!isMobile && mapDrawerOpen) {
      setMapDrawerOpen(false);
      return;
    }

    if (viewVariant === ListingDisplayVariant.map && !mapDrawerOpen && isMobile) {
      setMapDrawerOpen(true);
    }

    if (viewVariant === ListingDisplayVariant.full && mapDrawerOpen && isMobile) {
      setMapDrawerOpen(false);
    }
  }, [isMobile, viewVariant, mapDrawerOpen]);

  /**
   * turn off map drawer if search focus
   */
  useEffect(() => {
    if (app.searchFocus && mapDrawerOpen) {
      setViewVariant(ListingDisplayVariant.full);
    }
  }, [app.searchFocus, mapDrawerOpen]);

  /**
   * run a new search when the page number changes
   * app.searchTerm and app.searchFilters will both change the page number
   */
  useEffect(() => {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());

    if (viewVariant !== lastSearchQuery.viewVariant) {
      setLastSearchQuery({
        ...lastSearchQuery,
        ...{
          viewVariant,
          cards: resultsToCards({
            items: lastSearchQuery.searchResults.value,
            viewVariant,
            ads:
              (lastSearchQuery.pageNumber * 5) % 10 === 0
                ? [{ placement: 4 }, { placement: 11 }]
                : [{ placement: 6 }, { placement: 14 }],
            alwaysEager: true,
            onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
          }),
        },
      });
      return;
    }

    if (
      parseInt(params.get("page") ?? "1", 10) === lastSearchQuery.pageNumber &&
      ((app.searchTerm ?? "|none|") === (lastSearchQuery.searchTerm ?? "|none|") ||
        (app.searchTerm === "" && (lastSearchQuery.searchTerm ?? "") === "")) &&
      app.searchFilters?.searchId === lastSearchQuery.searchId
    ) {
      return;
    }

    Log.logToConsoleDebug("Listing.tsx", "Search varaibles triggered query", [
      {
        pageNumber: parseInt(params.get("page") ?? "1", 10) === lastSearchQuery.pageNumber,
        searchTerm:
          (app.searchTerm ?? "|none|") === (lastSearchQuery.searchTerm ?? "|none|") ||
          (app.searchTerm === "" && (lastSearchQuery.searchTerm ?? "") === ""),
        filters: app.searchFilters?.searchId === lastSearchQuery.searchId,
      },
      {
        oldPageNumber: lastSearchQuery.pageNumber,
        currentPageNumber: parseInt(params.get("page") ?? "1", 10),
        oldSearchId: lastSearchQuery.searchId,
        currentSearchId: app.searchFilters?.searchId,
        oldSearchTerm: lastSearchQuery.searchTerm ?? "|none|",
        currentSearchTerm: app.searchTerm ?? "|none|",
      },
    ]);

    runSearchQuery(lastSearchQuery, app, parseInt(params.get("page") ?? "1", 10));
  }, [
    app.searchTerm,
    app?.searchFilters?.filterState,
    nextNavigation.searchParams,
    lastSearchQuery,
    viewVariant,
  ]);

  /**
   * results grid
   */
  const filterTags = useMemo(
    () => filterDataToList(app.searchFilters?.filterState),
    [app.searchFilters?.filterState],
  );
  const pages = useMemo(
    () =>
      Math.ceil(
        (lastSearchQuery.searchResults?.["@odata.count"] ?? 0) / lastSearchQuery.resultsPerPage,
      ),
    [lastSearchQuery],
  );

  /**
   * when in debug mode show the search result in console
   */
  useEffect(() => {
    Log.logToConsoleDebug("Listing.tsx", "Search change", [
      { lastSearchQuery, initialGeoPoints, initialQuery, filterTags },
    ]);
  }, [lastSearchQuery, initialGeoPoints, initialQuery, filterTags]);

  /**
   * Run a search query after something changes
   *
   * @param lastSearchQuery
   * @param app
   * @param newPageNumber
   * @param newResultsPerPage
   */
  async function runSearchQuery(
    lastSearchQuery: LastSearchQuery,
    app: AppContextType,
    newPageNumber: number,
  ) {
    const from = newPageNumber ?? 1;
    const queryType = app.searchQueryType ?? ListingDisplayVariant.full;
    const query = SearchLogic.defaultQuery(lastSearchQuery.resultsPerPage);
    const filters = app?.searchFilters;

    query.search = SearchLogic.constructSearchTerm(app.searchQueryType, app.searchTerm);
    query.queryType = queryType;
    query.filter = SearchLogic.constructFilter(filters?.filterState);
    query.sessionId =
      URLHelper.urlGetParameter("searchSessionId") ?? initialQuery.sessionId ?? query.sessionId;

    //get the results for the page number
    const searchResponse = await BlaceV2API.SearchServiceV2.postSearchQuery({
      ...query,
      skip: from * lastSearchQuery.resultsPerPage - lastSearchQuery.resultsPerPage,
    });

    if (searchResponse.status !== 200 || typeof searchResponse.body === "undefined") {
      //TODO: add a toast for generic failures
      return;
    }

    //get the results for geo points without page restrictions
    const geoQuery = JSON.parse(JSON.stringify(query));
    geoQuery.select =
      "title, slug, dataType, locations, facts, categories, images, regions, capacity, price";
    geoQuery.top = 10000;
    //filter the map results to only venues
    geoQuery.filter = `${(geoQuery.filter ?? "").length > 0 ? `${geoQuery.filter} and ` : ""}search.in(dataType,'venue','|')`;
    const geoRespones = await BlaceV2API.SearchServiceV2.postSearchQuery(geoQuery);

    let geoPointsToShow = searchResponse.body.payload;
    if (searchResponse.status == 200 && geoRespones?.body?.payload) {
      geoPointsToShow = geoRespones?.body?.payload;
    }

    setLastSearchQuery({
      searchId: app.searchFilters?.searchId,
      searchTerm: app.searchTerm,
      resultsPerPage: lastSearchQuery.resultsPerPage,
      pageNumber: from,
      searchResults: searchResponse.body.payload,
      query: query,
      geoPoints: geoPointsToShow,
      viewVariant,
      cards: resultsToCards({
        items: searchResponse.body.payload.value,
        viewVariant,
        ads:
          (from * 5) % 10 === 0
            ? [{ placement: 4 }, { placement: 11 }]
            : [{ placement: 6 }, { placement: 14 }],
        alwaysEager: true,
        onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
      }),
    });

    window.scrollTo(0, 0);
  }

  function insertDefaultFilters(filters?: SearchFiltersType) {
    const dataTypeFilter =
      filters?.getFilterData<BlaceV2Type.SearchType.SearchDataType[]>("dataType");

    if (!dataTypeFilter) {
      filters?.setFilterData("dataType", [BlaceV2Type.SearchTypes.Venue]);
    }
  }

  /**
   * handle putting the results on map as markers
   *
   * @param {BlaceV2Type.SearchType.SearchItem[]} results - search results
   * @returns {MapLocationMarker[]}
   */
  function resultsToCoordinates(
    results?: BlaceV2Type.SearchType.SearchItem[],
  ): MapLocationMarker[] {
    if (!results || !Array.isArray(results)) {
      return [];
    }

    const markers: MapLocationMarker[] = [];
    for (const result of results) {
      for (const location of result.locations ?? []) {
        if (typeof location.latitude === "number" && typeof location.longitude === "number") {
          markers.push({
            lat: location.latitude,
            lng: location.longitude,
            listingData: result,
            to: cms.constructLink(`/${result.dataType}/${result.slug}`),
            slug: result.slug,
          });
        }
      }
    }
    return markers;
  }

  function handleMapToggle(overrideViewVariant?: keyof typeof ListingDisplayVariant): void {
    if (isMobile) {
      switch (listingModalState) {
        case MobileListingModalState.DEFAULT:
          handleListingModalDragToTop();
          return;
        case MobileListingModalState.CLOSED:
          handleListingModalDragToTop();
          return;
        case MobileListingModalState.HIDDEN:
          setListingModalState(MobileListingModalState.CLOSED);
          return;
        default:
          break;
      }
    }

    const toChange =
      overrideViewVariant ??
      (viewVariant === ListingDisplayVariant.map
        ? ListingDisplayVariant.full
        : ListingDisplayVariant.map);
    setViewVariant(toChange);
  }

  /**
   * toggle the map on / off
   *
   * @returns {void}
   */
  //TODO: make the map use a map button to toggle on / off in future
  //TODO: this code is a duplicate of ./component/MapButton.tsx
  function handleMapToggleMap() {
    const toChange =
      viewVariant === ListingDisplayVariant.map
        ? ListingDisplayVariant.full
        : ListingDisplayVariant.map;
    nextNavigation.updateQueryString("viewVariant", toChange);
  }

  /**
   * Translate the filterData state from useSearch for tags in the UI
   *
   * @param {Record<string,any>} filterData  - the filter data state from use search
   * @returns
   */
  function filterDataToList(filterData?: Record<string, any>): FilterTag[] {
    if (!filterData) {
      return [];
    }

    function getLabel(value: string, options?: AppSearchFilterType.AppSearchFilterOption[]) {
      for (const opt of options ?? []) {
        if (opt.value === value) {
          return opt.label;
        }
      }
    }

    const arr: FilterTag[] = [];

    for (const propertyKey of Object.keys(filterData)) {
      const filter = FILTERS[propertyKey];
      if (filter?.dataKey === "dataType") {
        continue;
      }
      if (filter) {
        const propertyData = filterData?.[filter.dataKey];
        if (filter.type === "multi-choice") {
          for (const opt of propertyData) {
            arr.push({
              isMulti: true,
              title: filter.title,
              data: getLabel(opt, filter.options) ?? "",
              dataKey: filter.dataKey,
              value: opt,
            });
          }
        } else if (filter.type === "capacity-slider") {
          const capacityValues = filterData?.[filter.dataKey]?.split("*");
          const isDefaultMin = capacityValues[0] === `${filter.capacitySliderMin}`;
          const isDefaultMax = capacityValues[1] === `${filter.capacitySliderMax}`;
          !isDefaultMin &&
            arr.push({
              isMulti: false,
              title: "min",
              defaultValue: filter.capacitySliderMin,
              data: `${filter.dataKey} from ${capacityValues[0]}`,
              dataKey: filter.dataKey,
              value: propertyData,
            });

          !isDefaultMax &&
            arr.push({
              isMulti: false,
              title: "max",
              defaultValue: filter.capacitySliderMax,
              data: `${filter.dataKey} to ${capacityValues[1]}`,
              dataKey: filter.dataKey,
              value: propertyData,
            });
        } else if (filter.type === "value-ge") {
          // TODO until all filters will be rewritten
          continue;
        } else {
          arr.push({
            isMulti: false,
            title: filter.title,
            data: getLabel(propertyData, filter.options) ?? "",
            dataKey: filter.dataKey,
            value: propertyData,
          });
        }
      }
    }
    return arr;
  }

  function getToLinkForPagination(page: number): string {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    params.set("page", `${page}`);
    return `${nextNavigation.pathname}?${params.toString()}`;
  }

  function handleListingModalDragToTop() {
    setListingModalState(MobileListingModalState.HIDDEN);
  }

  function handleListingModalDragToBottom() {
    setListingModalState(MobileListingModalState.CLOSED);
  }

  function getVenuesNumberMessage() {
    const totalVenues = lastSearchQuery.searchResults["@odata.count"] || 0;
    const currentPageVenues = lastSearchQuery.cards?.length;

    if (totalVenues > currentPageVenues) {
      return `Over ${currentPageVenues} venues`;
    }

    return `${lastSearchQuery.cards.length} venues`;
  }

  const pagination = pages > 1 && (
    <Pagination
      className={cn(styles.listingPagination, viewVariant)}
      count={pages}
      shape="rounded"
      size="large"
      siblingCount={1}
      boundaryCount={0}
      page={lastSearchQuery.pageNumber ?? 1}
      renderItem={(item) => (
        <Link
          href={getToLinkForPagination(item.page ?? 1)}
          className={styles.listingPaginationOption}
        >
          <PaginationItem {...item} />
        </Link>
      )}
    />
  );
  const resetFilters = useCallback(() => {
    app.searchFilters!.resetFilterData(listingType);
  }, [listingType]);

  return (
    <>
      <div className={cn(styles.listingHeader, viewVariant)}>
        {/*{false && backgroundImageCity && (*/}
        {/*  <div className={cn(styles.listingHeaderBgImageContainer, viewVariant)}>*/}
        {/*    <ImgBgResponsive*/}
        {/*      className={styles.listingHeaderBgImage}*/}
        {/*      imageUrl={ImageFromCDN.imageSizeAndQuality(*/}
        {/*        `/${backgroundImageCity}_bg_1.png`,*/}
        {/*        80,*/}
        {/*        isMobile ? 900 : 1600,*/}
        {/*        true,*/}
        {/*      )}*/}
        {/*      lazy={"eager"}*/}
        {/*    >*/}
        {/*      <div className={styles.listingHeaderBgImageOverlay} />*/}
        {/*    </ImgBgResponsive>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className={cn(styles.listingHeaderInner, viewVariant)}>
          <div className={styles.quickFiltersContainer}>
            <QuickFilters />
          </div>
          <div
            className={cn(styles.headerButtonsContainer)}
          >
            {/* add isCitySelected to show header background */}
            <DataTypeButtonGroup isCitySelected={false} />
            {showMap && (
              <MapButton
                // add isCitySelected to show header background
                isCitySelected={false}
                viewVariant={viewVariant}
                isMobileListingModalClosed={(listingModalState === MobileListingModalState.CLOSED) || (listingModalState === MobileListingModalState.DEFAULT)}
                handleMapToggle={handleMapToggle}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          styles.listingContainer,
          listingModalState !== MobileListingModalState.HIDDEN
            ? ListingDisplayVariant.map
            : viewVariant,
        )}
      >
        {listingModalState === MobileListingModalState.HIDDEN && (
          <>
            <div
              className={cn(styles.listingGrid, viewVariant)}
            >
              {lastSearchQuery.cards.length ?
                <ListingGrid variant={viewVariant} cards={lastSearchQuery.cards} />
                :
                <div className={`${styles.noResultsWrapper} ${viewVariant}`}>
                  <NoResults onReset={resetFilters} listingType={listingType} />
                  <PopularListings
                    listings={popularListings}
                    listingCategory={listingType ?? BlaceV2Type.SearchTypes.Venue}
                  />
                </div>
              }
              {pagination}
            </div>
            {showMap && (
              <div
                className={cn(styles.listingMap, viewVariant)}
              >
                <Map
                  city={app?.searchFilters?.filterState?.regions}
                  visible={true}
                  centerMarker={DEFAULT_LOCATION.NY}
                  markers={resultsToCoordinates(
                    lastSearchQuery.geoPoints.value ?? lastSearchQuery.searchResults.value ?? [],
                  )}
                  searchId={lastSearchQuery.searchId}
                  handleMapToggle={handleMapToggleMap}
                  hideMapButton={!isMobile}
                  hoveredSlug={hoveredListingSlug}
                />
              </div>
            )}
          </>
        )}

        {listingModalState !== MobileListingModalState.HIDDEN && (
          <>
            <DraggableListingView
              state={listingModalState}
              onModalDragToTop={handleListingModalDragToTop}
              onModalDragToBottom={handleListingModalDragToBottom}
            >
              <div className={styles.listingCount}>{getVenuesNumberMessage()}</div>
              {lastSearchQuery.cards.length ? (
                <ListingGrid variant={ListingDisplayVariant.map} cards={lastSearchQuery.cards} />
              ) : (
                <NoResults onReset={resetFilters} listingType={listingType} />
              )}
              {pagination}
            </DraggableListingView>
            <div
              className={cn(styles.listingMap, ListingDisplayVariant.map)}
            >
              <Map
                city={app?.searchFilters?.filterState?.regions}
                visible={true}
                centerMarker={DEFAULT_LOCATION.NY}
                markers={resultsToCoordinates(
                  lastSearchQuery.geoPoints.value ?? lastSearchQuery.searchResults.value ?? [],
                )}
                handleMapToggle={handleMapToggleMap}
                hideMapButton
                hoveredSlug={hoveredListingSlug}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Listing;
