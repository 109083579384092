import { PropsWithChildren } from "react";
import Draggable, { DraggableBounds, DraggableEventHandler } from "react-draggable";

interface DraggableComponentProps {
  handleStop: () => void;
  handleDrag: DraggableEventHandler;
  dragId: string;
  bounds?: DraggableBounds;
}

function DraggableComponent({
  handleStop,
  handleDrag,
  dragId,
  bounds,
  children,
}: PropsWithChildren<DraggableComponentProps>) {
  return (
    <Draggable
      position={{ x: 0, y: 0 }}
      onStop={handleStop}
      handle={dragId}
      axis="y"
      bounds={bounds}
      onDrag={handleDrag}
    >
      {children}
    </Draggable>
  );
}

export default DraggableComponent;
