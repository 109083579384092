import { createContext } from "react";
import type { SearchFiltersType, UseAuthType } from "@/src/hook";

export type AppContextSearchType = {
  searchTerm?: string;
  searchQueryType?: "full" | "simple";
  searchTermId?: string;
  userChange?: boolean;
};

export interface AppContextType {
  /** This prop is used on mobile to determine if the user has focused on the search input */
  searchFocus: boolean;
  /** This sets the prop above */
  setSearchFocus: (val: boolean) => void;
  /** This prop is used to controll state of other elements depending on prop value */
  showExtraFilters: boolean;
  /** This sets the prop above */
  setShowExtraFilters: (val: boolean) => void;
  /** Changes when the user clicks enter on a search term */
  searchTerm?: string;
  /** The type of search.  For partial term search use 'full'. */
  searchQueryType?: "full" | "simple";
  /** A unique ID for each new search term  */
  searchTermId?: string;
  /** changes when the user has engage with search term  */
  userChange?: boolean;
  /** All filters that the user has selected  */
  searchFilters?: SearchFiltersType;
  /** Search search term and filters */
  setSearch: (val: AppContextSearchType) => void;
  /** The auth context / result */
  auth: UseAuthType | undefined;
}

export const AppContext = createContext<AppContextType>({
  searchFocus: false,
  setSearchFocus: () => {},
  showExtraFilters: false,
  setShowExtraFilters: () => {},
  searchTerm: "",
  searchQueryType: "full",
  searchTermId: "noId",
  searchFilters: undefined,
  userChange: false,
  setSearch: ({}) => {},
  auth: undefined,
});
