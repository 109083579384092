import { SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type, ListingDisplayVariant } from "@/src/type";

export const getPopularListings = async (
  listingType: BlaceV2Type.SearchTypes,
  itemsCount: number,
  userRegion?: string,
  sessionId?: string
) => {
  const query = SearchLogic.defaultQuery(itemsCount);

  query.sessionId = sessionId || query.sessionId;
  query.queryType = ListingDisplayVariant.full;
  query.filter = SearchLogic.constructFilter({
    dataType: [listingType],
    regions: userRegion,
  });

  const searchResponse = await BlaceV2API.SearchServiceV2.postSearchQuery(query);
  
  return searchResponse?.body?.payload?.value || [];
};
