import { useEffect, useState } from "react";
import cn from "classnames";
import Head from "next/head";
import Link from "next/link";
import { useCMS } from "@/src/component/provider";
import { useBreakPointDown } from "@/src/hook";
import { ImageLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import { ListingCardType } from "@/src/type/component/Listing";
import ListingCardInnerV2 from "./ListingCardInnerV2";
import styles from "./ListingCardV2.module.scss";

interface ListingCardProps {
  item: BlaceV2Type.SearchType.SearchItem;
  eager: boolean;
  inquiryId?: string;
  isMapPreview?: boolean;
  onHover?: (listingSlug?: string) => void;
  type?: ListingCardType;
}

function ListingCard({ item, eager, inquiryId, isMapPreview = false, onHover, type }: ListingCardProps) {
  const cms = useCMS();
  const color = "black";
  const id = `ListingCard.${item.slug}`;
  const location = item.locations?.[0];
  const [showCard, setShowCard] = useState<boolean>(eager);
  const mainImageUrl = ImageLogic.getMainImageUrl(item, 80, 800);
  const isMobile = useBreakPointDown("md");

  const onMouseEnter = () => {
    //when in mobile hover effect rerenders the draggablePaper and we don't really need the hover on mobile
    if (!isMobile) {
      onHover?.(item.slug);
    }
  };

  const onMouseLeave = () => {
    if (!isMobile) {
      onHover?.();
    }
  };

  /**
   * if eager flag changes show the card
   */
  useEffect(() => {
    if (eager && !showCard) {
      setShowCard(true);
    }
  }, [eager, showCard]);

  /**
   * lazy load this card
   */
  useEffect(() => {
    if (showCard) {
      return;
    }

    function handleScrollListener() {
      const offset = 100;
      const thisElement = document.getElementById(id);
      const bottom = window.scrollY + window.innerHeight + offset;
      if (bottom > (thisElement?.getBoundingClientRect()?.top ?? 0)) {
        setShowCard(true);
      }
    }

    handleScrollListener();
    window.addEventListener("scroll", handleScrollListener, { passive: true });
    window.addEventListener("resize", handleScrollListener);
    return () => {
      window.removeEventListener("scroll", handleScrollListener);
      window.removeEventListener("resize", handleScrollListener);
    };
  }, [id, showCard]);

  return (
    <div className={cn({
        [styles.popularListingCard]: type === ListingCardType.POPULAR
      })}
    >
      {showCard && mainImageUrl && (
        <Head>
          <link rel="preload" href={`${mainImageUrl}`} as="image" />
        </Head>
      )}
      <Link
        href={{
          pathname: cms.constructLink(`/${item?.dataType}/${item?.slug}`),
          ...(inquiryId ? { query: { inquiryId } } : {}),
        }}
        target={isMapPreview ? "_blank" : undefined}
        className={styles.hideLinkStyles}
        title={`${item.title} by BLACE, ${(item.categories ?? []).join(", ")}`.trim()}
        id={id}
        aria-label={`Go To ${item.title}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid="listing-card-v2"
      >
        <div className={cn(styles.listingCard, { [styles.mapPreview]: isMapPreview })}>
          {showCard && (
            <ListingCardInnerV2
              item={item}
              color={color}
              location={location}
              showCard={showCard}
              isMobile={isMobile}
              isMapPreview={isMapPreview}
            />
          )}
          <noscript>
            <ListingCardInnerV2
              item={item}
              color={color}
              location={location}
              showCard={true}
              isMobile={isMobile}
              isMapPreview={isMapPreview}
            />
          </noscript>
        </div>
      </Link>
    </div>
  );
}

export default ListingCard;
