import { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useApp, useCMS } from "@/src/component/provider";
import { EMAIL_VALIDATION_REGEXP, URL_PARAMS } from "@/src/const";
import { useNextNavigation } from "@/src/hook";
import { ActivityLogic, AuthLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { Log, sleep } from "@/src/util";
import { StringHelper } from "@/src/util/helper";
import type { FormikProps } from "formik";

export type InquiryFormTypeFormik = BlaceV2Type.EventType.Inquiry;

export type InquiryFormType = {
  isLoading: boolean;
  saveComplete: boolean;
  hasError?: string;
  formik: FormikProps<InquiryFormTypeFormik>;
  inquiryId?: string;
};

interface UseInquiryFormProps {
  slug?: string;
  altCatalogId?: string;
  catalogId?: string;
  searchId?: string;
  inquiryType?: BlaceV2Type.EventType.InquiryType;
  maxGuestCount: number;
  inquiryFormData?: BlaceV2Type.EventType.InquiryFormDataProps;
  sendInquiryDialogClose?: () => void;
  setInquiryLimitReached: () => void;
}

function covertBudgetToValue(budget?: string): number {
  switch (budget ?? "default") {
    case "unknown":
    case "0-5000":
      return 2500;
    case "5000-20000":
      return 12500;
    case "20000-50000":
      return 35000;
    case "50000-100000":
      return 75000;
    case "100000+":
      return 100000;
  }
  return 2500;
}

export function useInquiryForm({
  inquiryType,
  altCatalogId,
  catalogId,
  searchId,
  slug,
  maxGuestCount,
  inquiryFormData,
  sendInquiryDialogClose,
  setInquiryLimitReached,
}: UseInquiryFormProps): InquiryFormType {
  const nextNavigation = useNextNavigation();
  const cms = useCMS();
  const app = useApp();
  const [started, setStarted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasError, setHasError] = useState<string | undefined>(undefined);
  const [inquiryId, setInquiryId] = useState<string | undefined>(undefined);
  const successStatusCode = 200;
  const inquiryLimitStatusCode = 429;
  const phoneRegExp = /^[0-9\s\-+()]+$/;

  //https://codesandbox.io/s/yup-date-validation-g7nxj
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        clientName: Yup.string()
          .required("A name is required")
          .min(3, "Users must input at least three characters")
          .max(255, "Full Name is too long"),
        phone: Yup.string()
          .matches(phoneRegExp, "Only numbers are valid")
          .min(17, "Phone number is too short")
          .max(18, "Full Name is too long")
          .nullable(),
        email: Yup.string()
          .matches(EMAIL_VALIDATION_REGEXP, "Provide a valid email")
          .max(255, "Email is too long")
          .required("Email is required"),
        eventName: Yup.string()
          .matches(/^[^*]*$/, "Error: '*' not accepted")
          .min(3, "Event name is required")
          .max(255, "Event name is too long")
          .required("Event name is required"),
        startDate: Yup.date().required("Start Date is required"),
        endDate: Yup.date().required("End Date is required"),
        budget: Yup.string().required("Budget is required"),
        guests: Yup.number()
          .min(1, "Guest count is required")
          .max(maxGuestCount, `Max guest count is ${maxGuestCount}`)
          .required("Guest count is required"),
        notes: Yup.string()
          .min(22, "A note is required")
          .max(3999, "Note too long")
          .required("A note is required"),
      }),
    [maxGuestCount],
  );

  const startDateInitialValue = inquiryFormData?.startDate
    ? new Date(inquiryFormData?.startDate)
    : null;
  const endDateInitialValue = inquiryFormData?.endDate ? new Date(inquiryFormData?.endDate) : null;
  const phoneInitialValue = inquiryFormData?.phone
    ? StringHelper.formatPhoneNumber(inquiryFormData?.phone)
    : "";

  /**
   * submit email to the backend to save as a lead
   */
  const formik = useFormik<InquiryFormTypeFormik>({
    enableReinitialize: true,
    initialValues: {
      inquiryStatus: "draft",
      inquiryType,
      email: "",
      clientName: "",
      phone: phoneInitialValue,
      budget: inquiryFormData?.budget || "",
      eventName: inquiryFormData?.eventName || "",
      guests: inquiryFormData?.guests || "",
      flexibleDates: true,
      //@ts-ignore
      startDate: startDateInitialValue,
      //@ts-ignore
      endDate: endDateInitialValue,
      altCatalogId,
      catalogId,
      searchId,
      slug,
      notes: inquiryFormData?.notes || "",
      extras: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const currentUser = AuthLogic.getAuthCurrentUser();
      if (currentUser) {
        values.email = currentUser.email;
        values.clientName = currentUser.name;
      }
      values.sessionId = await ActivityLogic.SESSION_ID();
      const cleanedPhone = StringHelper.cleanPhoneNumber(values.phone ?? "");
      values.phone = cleanedPhone;

      const response = await BlaceV2API.EventServiceV2.postInquirySubmit(values);

      if (response.status === inquiryLimitStatusCode) {
        setInquiryLimitReached();
        setIsLoading(false);
        sendInquiryDialogClose && sendInquiryDialogClose();
        return;
      }

      if (response.body?.metadata.statusCode === successStatusCode) {
        const thisInquiryId = response.body.payload.inquiryId;

        //track the user
        ActivityLogic.facebookTracking("track", "Lead");
        ActivityLogic.ga4Tracking(
          "generate_lead",
          {
            currency: "USD",
            value: covertBudgetToValue(values.budget),
          },
          true,
        );
        await ActivityLogic.toActivityService({
          action: "submitInquiry",
          actionId: thisInquiryId,
          actionIdType: "inquiry",
          locationInApp: "useInquiryForm.ts",
        });

        //allow the facebook pixel to fire
        await sleep(150);

        //send the user to the next step
        const toastMessage = `${URL_PARAMS.TOAST.TOAST_MESSAGE}=${URL_PARAMS.TOAST.VALUES.TOAST_MESSAGE.INQUIRY_COMPLETE}`;
        const toastLevel = `${URL_PARAMS.TOAST.TOAST_LEVEL}=${URL_PARAMS.TOAST.VALUES.TOAST_LEVEL.SUCCESS}`;
        const queryParams = `?${toastMessage}&${toastLevel}&email=${values.email}&fullName=${values.clientName}`;
        const requiresAuth = app.auth?.requireAuthToContinue(
          undefined,
          queryParams,
          `/inquiry/${thisInquiryId}/callback`,
          true,
        );

        if (!requiresAuth) {
          nextNavigation.router.push(cms.constructLink(`/inquiry/${thisInquiryId}/complete`));
        }
        setInquiryId(thisInquiryId);
        setSaveComplete(true);
      } else {
        Log.logToDataDog(Log.LogLevel.ERROR, "useInquiryForm.tsx", "inquiryError", [response.body]);
      }

      setIsLoading(false);
      sendInquiryDialogClose && sendInquiryDialogClose();
    },
  });

  useEffect(() => {
    if (started) {
      return;
    }

    if (
      formik.values.eventName ||
      formik.values.notes ||
      formik.values.startDate ||
      formik.values.endDate ||
      formik.values.budget ||
      formik.values.guests
    ) {
      ActivityLogic.ga4Tracking("generate_lead_initiated", {});
      ActivityLogic.toActivityService({
        action: "inquiryInitiated",
        locationInApp: "useInquiryForm.ts",
      });
      setStarted(true);
    }
  }, [started, formik.values]);

  useEffect(() => {
    if (formik.values.startDate && !formik.values.endDate) {
      formik.setFieldValue("endDate", formik.values.startDate);
    }
  }, [formik.values.startDate, formik.values.endDate]);

  return {
    isLoading,
    saveComplete,
    hasError,
    formik,
    inquiryId,
  };
}
