//env variables
export const DEBUG_DEV_MODE: boolean = process.env.DEBUG_DEV_MODE === "true";
export const APP_TENANT: string = process.env.APP_TENANT ?? "blace";
export const ENV: string | undefined = process.env.ENV;
export const ASSET_URL: string | undefined = process.env.ASSET_URL;
export const TEXT_CDN_URL: string | undefined = process.env.TEXT_CDN_URL;
export const TEXT_CDN_FRONTEND: string | undefined = `${TEXT_CDN_URL}/frontend`;
export const REPLACE_STORAGE_URL: string | undefined = process.env.REPLACE_STORAGE_URL;
export const APP_NAME: string | undefined = process.env.APP_NAME;
export const APP_API: string | undefined = process.env.APP_API;
export const BLACE_V2_API: string | undefined = process.env.BLACE_V2_API;
export const B2B_AUTH_ENDPOINT: string | undefined =
  process.env.B2B_AUTH_ENDPOINT;
export const B2B_URL: string | undefined = process.env.B2B_URL;
export const GOOGLE_MAPS_KEY: string | undefined = process.env.GOOGLE_MAPS_KEY;
export const CMS_REVALIDATED_PERIOD: number = parseInt(
  process.env.CMS_REVALIDATED_PERIOD ?? "86400",
  10
); //auto re-cache the page once a day
export const DATADOG_TOKEN: string = process.env.DATADOG_TOKEN ?? "";
export const IS_LOCALHOST: boolean = (process.env.IS_LOCALHOST ?? false) === "true";
export const DEFAULT_ROUTE: string | undefined = process.env.DEFAULT_ROUTE;
export const SET_APP_MODE: string = process.env.SET_APP_MODE ?? "digital";
export const FEATURE_HUB_KEY: string | undefined = "";
export const FEATURE_HUB_URL: string | undefined = "";
export const FEATURE_HUB_APP_ID: string | undefined = "";
export const PREFERRED_URL: string = process.env.PREFERRED_URL ?? "https://blace.com";
export const STRAPI_API_URL: string = process.env.STRAPI_API_URL ?? "";
export const STRAPI_API_TOKEN: string = process.env.STRAPI_API_TOKEN ?? "";
export const APP_PLATFORM: string = "web";
export const SEO_URL: string = process.env.SEO_URL ?? "";
export const REVALIDATE_DEFAULT = 60 * 60 * 8; //8 hours
export const REVALIDATE_DATA = 60 * 10; //10 minutes
export const BLACE_EMAIL = "info@blace.com";
export const HOME_TO = "/discover";
export const DEFAULT_RETURN_AUTH_TO = "/discover?navDrawer=open";
export const DEFAULT_RETURN_AUTH_TO_PARTNER = "/a/dashboard";
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z$&+,:;=?@#|'<>.-^*()%!]{3,}$/;
export const VENUE_MAX_GUEST_COUNT = 10000;
export const VENDOR_MAX_GUEST_COUNT = 5000;
export const DEFAULT_LOGO: string = "blaceLogo";
export const SYSTEM_DATE_FORMAT = "yyyy-MM-dd";
export const EMAIL_VALIDATION_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
