import { createContext } from "react";
import { BlaceV2Type } from "@/src/type";

export interface DashboardContextType {
  selectedInquiry?: BlaceV2Type.InquirySearchType | null;
  searchItemImageUrl?: string;
  searchItemInitials?: string;
  searchItemTitle?: string;
  clientInitials?: string;
  clientName?: string;
  currentUserName?: string;
  currentUserInitials?: string;
  currentUserPhotoUrl?: string;
  searchId?: string;
}

export const DashboardContext = createContext<DashboardContextType>({ });
