// TODO: remove the component and its tests after March 2025
import { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from "@mui/material";
import { BaseButton } from "@/src/component/base";
import { CapacitySlider } from "@/src/component/partial/FilterPanel/component/CapacitySlider";
import { useApp } from "@/src/component/provider";
import { SEARCH_ID_INITIALIZE } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { AppSearchFilterType } from "@/src/type";
import styles from "./FilterPanel.module.scss";

interface Props extends AppSearchFilterType.AppSearchFilter {}

/**
 * this transforms the filterUsing property of filter.options into a map to search
 * againsts the values of the filtered state
 *
 * @param {AppSearchFilterType.AppSearchFilterOptionUsing[]} filterUsing - the filterUsing property of filter.options
 */
function filterUsingOptionsToMap(
  filterUsing: AppSearchFilterType.AppSearchFilterOptionUsing[]
) {
  const map: Record<string, any> = {};
  for (const opt of filterUsing) {
    map[opt.dataKey] = opt.value;
  }
  return map;
}

function FilterPanelSection({
  title,
  dataKey,
  type,
  options,
  startOpen,
  filterUsingKeys,
  capacitySliderMax,
  capacitySliderMin
}: Props) {
  const app = useApp();
  const [isOpen, setIsOpen] = useState<boolean>(!!startOpen);
  const [expand, setExpand] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<
    AppSearchFilterType.AppSearchFilterOption[] | undefined
  >(options);
  const [lastSearchId, setLastSearchId] = useState<string>("check");
  const [inUseCount, setInUseCount] = useState<number>(0);
  const [capacitySlideValues, setCapacitySlideValues] = useState<number[]>([capacitySliderMin ?? 0, capacitySliderMax ?? 0]);

  const isNoFiltersAvailable = (filteredOptions ?? []).length === 0;

  /**
   * this allows a another filter's selection to reduce the options by the filterUsing property
   */
  useEffect(() => {
    //if already checked for search / filter do not search again
    if (app.searchFilters?.searchId === lastSearchId) {
      return;
    }

    //ensure that the data required is present
    if (!filterUsingKeys || !options || !app?.searchFilters?.filterState) {
      return;
    }

    //get the applied filter dataKey from the filter state
    const appliedFilterKeys: string[] = Object.keys(
      app.searchFilters.filterState
    );
    if (!appliedFilterKeys) {
      setLastSearchId(app.searchFilters.searchId);
      setFilteredOptions(options);
      return;
    }

    //check if any filterUsing and the applied dataKeys match
    const found = filterUsingKeys.some(key => appliedFilterKeys.includes(key));
    if (!found) {
      setLastSearchId(app.searchFilters.searchId);
      setFilteredOptions(options);
      return;
    }

    //filter the options based on the applied state
    const filtered: AppSearchFilterType.AppSearchFilterOption[] = [];
    for (const opt of options ?? []) {
      if (Array.isArray(opt.filterUsing)) {
        //check if the filters are applied
        let applied = filterUsingKeys.length;
        const filterUsingMap = filterUsingOptionsToMap(opt.filterUsing);

        for (const filterUsingKey of filterUsingKeys) {
          const filterUsingValue = filterUsingMap[filterUsingKey];
          //when the filter is in use check if the value applied
          const values =
            app?.searchFilters?.filterState?.[filterUsingKey ?? ""];
          if (values) {
            if (Array.isArray(values) && values.includes(filterUsingValue)) {
              applied -= 1;
            } else if (values === filterUsingValue) {
              applied -= 1;
            }
          }
          //when the filter is not in use count as applied;
          else {
            applied -= 1;
          }
        }

        if (applied === 0) {
          filtered.push(opt);
        }
      }
    }

    setLastSearchId(app.searchFilters.searchId);
    setFilteredOptions(filtered);
  }, [app.searchFilters, options, filterUsingKeys]);

  /**
   * determine the counter of filters in use
   */
  useEffect(() => {
    const values = app.searchFilters?.getFilterData(dataKey);
    if (type === "capacity-slider") {
      const capacityValues = (values as string ?? "").split("*");
      const isDefaultMin = capacityValues[0] === `${capacitySliderMin}` || !capacityValues[0];
      const isDefaultMax = capacityValues[1] === `${capacitySliderMax}` || !capacityValues[1];
      const count = isDefaultMax && isDefaultMin ? 0 : isDefaultMax || isDefaultMin ? 1 : 2;
      if (inUseCount !== count) {
        setInUseCount(count);
      }
      return;
    }
    if (values) {
      let count = 1;
      if (Array.isArray(values)) {
        count = values.length;
      }

      if (inUseCount !== count) {
        setInUseCount(count);
      }
      return;
    }

    if (inUseCount !== 0) {
      setInUseCount(0);
    }
  }, [inUseCount, app.searchFilters, dataKey]);

  useEffect(() => {
    if (lastSearchId === "check" || lastSearchId === SEARCH_ID_INITIALIZE) {
      return;
    }

    if ((filteredOptions ?? []).length === 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [filteredOptions, lastSearchId]);

  useEffect(() => {
    if (dataKey !== "capacity") {
      return;
    }
    
    const currentSearchDataType = app.searchFilters?.getFilterData<string[]>("dataType");
    const isVendorType = (currentSearchDataType ?? [])[0]  === "vendor" && currentSearchDataType?.length === 1;
    const capacityValue = app.searchFilters?.getFilterData<string>(dataKey) ?? "";

    if (isVendorType && capacityValue) {
      app.searchFilters?.setFilterData(dataKey, "");
      return;
    }

    if (!capacityValue) {
      setCapacitySlideValues([capacitySliderMin ?? 0, capacitySliderMax ?? 0]);
      return;
    }

    if (lastSearchId !== app.searchFilters?.searchId) {
      const filteredCapacityValues = capacityValue.split("*").map((capacity) => Number(capacity));
      setCapacitySlideValues(filteredCapacityValues);
    }
  }, [app.searchFilters, lastSearchId, dataKey]);
  

  function limitOptions(
    options: AppSearchFilterType.AppSearchFilterOption[],
    expand: boolean
  ): AppSearchFilterType.AppSearchFilterOption[] {
    if (expand || options.length < 5) {
      return options;
    }
    const clone = JSON.parse(JSON.stringify(options));
    return clone.splice(0, 5);
  }

  function checkboxIsActive(val: string, filterData: string[]): boolean {
    if (!Array.isArray(filterData)) {
      return false;
    }

    for (const opt of filterData) {
      if (opt === val) {
        return true;
      }
    }

    return false;
  }

  function setSingleChoice(changeTo: string) {
    if ((app.searchFilters?.getFilterData(dataKey) ?? "") === "") {
      app.searchFilters?.setFilterData(dataKey, changeTo);
      return;
    }
    const newValue =
      changeTo === (app.searchFilters?.getFilterData(dataKey) ?? "")
        ? ""
        : changeTo;
    app.searchFilters?.setFilterData(dataKey, newValue);
  }

  const applyCapacityFilterChange = (value: number[]) => {
    app.searchFilters?.setFilterData(dataKey, value);
  };

  return (
    <div className={styles.listingAttrSection}>
      <BaseButton
        type="button"
        variant="text"
        color="primary"
        className={styles.listingAttrTitleContainer}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.listingAttrTitleContainerInner}>
          <Typography variant="body1" className={styles.listingAttrTitleTxt}>
            {title}
          </Typography>
          {inUseCount > 0 && (
            <div className={styles.filterCountRel}>
              <div className={styles.filterCountAbs}>{inUseCount}</div>
            </div>
          )}
        </div>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </BaseButton>
      <Collapse in={isOpen}>
        {isNoFiltersAvailable && (
          <div className={styles.listingAttrFiltersNotAvailable}>
            <Typography variant="body2" className={styles.listingAttrTitleTxt}>
              No filters available
            </Typography>
          </div>
        )}
        {!type.includes("slider") && (
          <RadioGroup
            value={
              type === "single-choice"
                ? app.searchFilters?.getFilterData(dataKey) ?? ""
                : undefined
            }
            className={styles.listingAttrOptionsContainer}
          >
            <>
              {limitOptions(filteredOptions ?? [], expand).map((opt, i) => (
                <FormControlLabel
                  key={i}
                  className={styles.listingAttrOptions}
                  label={
                    <>
                      <Typography variant="body2">{opt.label}</Typography>
                      {opt.subtitle && (
                        <p className={styles.filterOptionSubtitle}>
                          {opt.subtitle}
                        </p>
                      )}
                    </>
                  }
                  control={
                    type === "single-choice" ? (
                      <Radio
                        value={opt.value}
                        onClick={() => {
                          setSingleChoice(opt.value);
                          SearchLogic.trackFilterUsed(opt.value, dataKey, "FilterPanelSection.tsx");
                        }}
                      />
                    ) : (
                      <Checkbox
                        value={opt.value}
                        checked={checkboxIsActive(
                          opt.value,
                          app.searchFilters?.getFilterData(dataKey) ?? []
                        )}
                        onChange={() => {
                          app.searchFilters?.setMultiChoice(dataKey, opt.value);
                          SearchLogic.trackFilterUsed(opt.value, dataKey, "FilterPanelSection.tsx");
                        }}
                      />
                    )
                  }
                />
              ))}
            </>
            {(filteredOptions ?? []).length > 4 && (
              <div className={styles.listingAttrOptionsExpandButton}>
                <BaseButton
                  type="button"
                  variant="text"
                  color="secondary"
                  aria-label={`Show ${expand ? "less" : "more"} ${title}`}
                  onClick={() => setExpand(!expand)}
                >
                  {expand ? "Show less" : "show more"}
                </BaseButton>
              </div>
            )}
          </RadioGroup>
        )}
        {type === "slider" && (
          <div className={styles.listingAttrOptionsSlider}>
            <Slider aria-label={title} />
          </div>
        )}
        {type === "capacity-slider" && !isNoFiltersAvailable && (
          <div className={styles.listingAttrOptionsSlider}>
            <CapacitySlider
              sliderMin={capacitySliderMin ?? 0}
              sliderMax={capacitySliderMax ?? 0}
              handleCapacitySliderChangeCommit={applyCapacityFilterChange}
              handleCapacitySliderChange={setCapacitySlideValues}
              capacityValue={capacitySlideValues}
            />
          </div>
        )}
      </Collapse>
    </div>
  );
}

export default FilterPanelSection;
