import { FILTERS } from "@/src/const";
import { SearchType } from "@/src/type/blaceV2";
import {
  DetailSectionInfo,
  DetailSectionInfoBody,
} from "@src/component/partial";
import styles from "./VendorFeatures.module.scss";

interface VendorFeaturesProps {
  vendorFeatures?: SearchType.SearchAttribute[];
}

function VendorFeatures({ vendorFeatures }: VendorFeaturesProps) {
  if (!vendorFeatures?.length) {
    return false;
  }

  const specialFeaturesDataKey = FILTERS.specialFeatures?.dataKey || "";
  const specialFeaturesAvailableOptions = FILTERS.specialFeatures?.options
    ?.map((option) => option.value) || [];

  return <DetailSectionInfo
    title="Special Features"
    Component={
      <div className={styles.vendorFeatures}>
        {vendorFeatures.map(({ attributeId, attributeType, label }) => {
          if (attributeType !== specialFeaturesDataKey || !specialFeaturesAvailableOptions.includes(attributeId)) {
            return false;
          }

          return (
            <div key={`${label}`} className={styles.vendorFeaturesTag}>
              <DetailSectionInfoBody tag="body1">
                {label}
              </DetailSectionInfoBody>
            </div>
          );
        })}
      </div>
    }
  />;
}

export default VendorFeatures;
