import { SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { Box, TextareaAutosize, Typography } from "@mui/material";
import cn from "classnames";
import { BaseButton } from "@/src/component/base/BaseButton";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { ChatLogMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage";
import { Fetching } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatTabFallback/Fetching";
import { KEYS, SERVER_ERROR } from "@/src/const";
import { URLFilterLogic } from "@/src/model";
import { InquiryServiceV2 } from "@/src/service/BlaceV2";
import { BlaceV2Type } from "@/src/type";
import { InquiryRecepientTypes } from "@/src/type/blaceV2";
import { Log } from "@/src/util";
import { SharedConfigManager } from "@/src/util/SharedConfigManager";
import styles from "./FeedbackMessage.module.scss";

interface FeedbackMessageProps {
  message: BlaceV2Type.InquiryLog;
}
export interface FeedbackClientData {
  rating: number | null,
  comment?: string
}

function FeedbackMessage({ message }: FeedbackMessageProps) {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(message.shortProperties?.submitted || false);
  const [indexSelectedRating, setIndexSelectedRating] = useState<null | number>(null);
  const [feedbackMessageValue, setFeedbackMessageValue] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const dashboardContext = useContext(DashboardContext);

  const { recipientType } = message.shortProperties;
  const showToClient  = useMemo(
    () => !recipientType || recipientType === InquiryRecepientTypes.Client,
    [recipientType]
  );

  const selectedItemTitle = dashboardContext.selectedInquiry?.searchItem.title;

  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  const rateList = [
    { rate: 1, emojiPath: `${SVG_URL}/EmojiRatingFirst.svg` },
    { rate: 2, emojiPath: `${SVG_URL}/EmojiRatingSecond.svg` },
    { rate: 3, emojiPath: `${SVG_URL}/EmojiRatingThird.svg` },
    { rate: 4, emojiPath: `${SVG_URL}/EmojiRatingFourth.svg` },
    { rate: 5, emojiPath: `${SVG_URL}/EmojiRatingFifth.svg` },
  ];

  const sendRequestHandler = async (markAsSubmitted: boolean = true) => {
    setIsLoading(true);
    setIsFeedbackSubmitted(false);
    setIsError(false);

    const data: FeedbackClientData = {
      rating: indexSelectedRating,
      comment: feedbackMessageValue,
    };

    try {
      const response = await InquiryServiceV2.postInquiryFeedback(message.inquiryLogId, data);
      if (response.status === 200) {
        setIsLoading(false);
        if (markAsSubmitted) {
          setIsFeedbackSubmitted(true);
        }
      } else {
        setIsLoading(false);
        setIsError(true);
        Log.logToDataDog(Log.LogLevel.ERROR, "FeedbackMessage.tsx", "FeedbackMessageError", [response]);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      Log.logToDataDog(Log.LogLevel.ERROR, "FeedbackMessage.tsx", "FeedbackMessageError", [error]);
    }
  };

  const submitFeedbackHandler = () => {
    sendRequestHandler();
  };

  const handleRatingSelect = (ratingValue: number) => setIndexSelectedRating(ratingValue);
  

  const handleChangeMessageValue = (event: { target: { value: SetStateAction<string> };}) => setFeedbackMessageValue(event.target.value);
  

  const getListItemClasses = (index: number) => {
    return index === indexSelectedRating ? [styles.ratingListItem, styles.selected] : [styles.ratingListItem];
  };

  const textboxClasses = useMemo(() => isError ? [styles.textBox, styles.error] : [styles.textBox], [isError]);

  useEffect(() => {
    const {rating, comment} = message.shortProperties;
    !indexSelectedRating && rating && setIndexSelectedRating(rating);
    !feedbackMessageValue && comment && setFeedbackMessageValue(comment);
  }, [message]);

  // handle the direct link (e.g. from email) to the NPS with automatic BE request
  useEffect(() => {
    const { inquiryLogId, rating } = URLFilterLogic.getFeedbackParamsFromURL();

    const isNeedFeedbackSubmit = inquiryLogId && rating && inquiryLogId === message.inquiryLogId && !message.shortProperties?.submitted;

    if (isNeedFeedbackSubmit) {
      const ratingValue = Number(rating);
      if (ratingValue !== indexSelectedRating) {
        setIndexSelectedRating(ratingValue);
      }
      // we need it to prevent `null` sending, we need to wait till state will change
      if (indexSelectedRating && ratingValue === indexSelectedRating) {
        sendRequestHandler(false);
        // we need to clean the params to unblock usual flow
        URLFilterLogic.removeFeedbackParamsFromURL();
      }
    }
  },[message, indexSelectedRating]);

  if (!showToClient) {
    return <></>;
  }

  return (
    <ChatLogMessage
      inquiryMessage={message}
      contentWithBorder
      isFeedbackSubmitted={isFeedbackSubmitted}
    >
      <Box className={styles.contentWrapper}>
        <Typography className={styles.rateTitle}>
          Please rate your experience with {selectedItemTitle}
        </Typography>
        <ul className={styles.ratingList}>
          {rateList.map((ratingItem) => (
            <li
              key={ratingItem.rate}
              className={cn(getListItemClasses(ratingItem.rate))}
              onClick={() => handleRatingSelect(ratingItem.rate)}
            >
              <img src={ratingItem.emojiPath} height="24px" width="24px" alt="rating emoji"/>
            </li>
          ))}
        </ul>
        {isLoading && <Fetching sizeValue={25}/>}
        {indexSelectedRating && !isLoading && (
          <>
            <TextareaAutosize
              className={cn(textboxClasses)}
              placeholder={`Tell us more about it (responses will not be shared with the ${selectedItemTitle })`}
              value={feedbackMessageValue}
              onChange={handleChangeMessageValue}
            />
            {isError &&  <Typography className={styles.errorMessage}>{SERVER_ERROR}</Typography>}
            <BaseButton
              onClick={submitFeedbackHandler}
              className={styles.sendButton}
              disabled={isLoading}
            >
              Send
            </BaseButton>
          </>
        )}
      </Box>
    </ChatLogMessage>
  );
}

export default FeedbackMessage;
