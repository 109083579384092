import { StatusFilters } from "@/src/component/view/Dashboard/component/LeftContainer/components/Filters/StatusFilter";
import { AppSearchFilterType, BlaceV2Type } from "@/src/type";

export const FILTERS: Record<string, AppSearchFilterType.AppSearchFilter> = {
  isExclusive: {
    dataKey: "isExclusive",
    noTags: true,
    property: "facts/isExclusive",
    propertyType: "boolean",
    title: "Blace Exclusive",
    type: "single-choice",
    startOpen: true,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "BLACE Exclusive",
        value: "true",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
    ],
  },
  dataType: {
    dataKey: "dataType",
    property: "dataType",
    propertyType: "string",
    title: "Type",
    type: "multi-choice",
    startOpen: true,
    options: [
      { label: "Vendor", value: "vendor" },
      { label: "Venue", value: "venue" },
    ],
  },
  regions: {
    dataKey: "regions",
    property: "regions",
    propertyType: "simple-array",
    title: "City",
    type: "single-choice",
    startOpen: true,
    options: [
      { label: "New York", value: BlaceV2Type.Regions.NY },
      { label: "Los Angeles", value: BlaceV2Type.Regions.LA },
    ],
  },
  areas: {
    dataKey: "areas",
    property: "locations/district",
    propertyType: "complex-array",
    title: "Area",
    type: "multi-choice",
    startOpen: false,
    filterUsingKeys: ["regions", "dataType"],
    options: [
      {
        label: "Manhattan",
        value: "Manhattan",
        subtitle: "New York",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Brooklyn",
        value: "Brooklyn",
        subtitle: "New York",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Queens",
        value: "Queens",
        subtitle: "New York",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "dataType", value: "venue" },
        ],
      },
    ],
  },
  neighborhood: {
    dataKey: "neighborhood",
    title: "Neighborhood",
    type: "multi-choice",
    property: "locations/neighborhood",
    propertyType: "complex-array",
    startOpen: false,
    filterUsingKeys: ["regions", "areas", "dataType"],
    options: [
      //Brooklyn
      {
        label: "Bushwick",
        value: "Bushwick",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Crown Heights",
        value: "Crown Heights",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Downtown Brooklyn",
        value: "Downtown Brooklyn",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Dumbo",
        value: "Dumbo",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "East Williamsburg",
        value: "East Williamsburg",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Greenpoint",
        value: "Greenpoint",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Little Caribbean",
        value: "Little Caribbean",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Prospect Heights",
        value: "Prospect Heights",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Red Hook",
        value: "Red Hook",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Sunset Park",
        value: "Sunset Park",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Vinegar Hill",
        value: "Vinegar Hill",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Williamsburg",
        value: "Williamsburg",
        subtitle: "Brooklyn, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Brooklyn" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      //Manhattan
      {
        label: "Chelsea",
        value: "Chelsea",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Financial District",
        value: "Financial District",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Flatiron",
        value: "Flatiron District",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Garment District",
        value: "Garment District",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Greenwich Village",
        value: "Greenwich Village",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Hell's Kitchen",
        value: "Hells Kitchen",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Hudson Square",
        value: "Hudson Square",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Lenox Hill",
        value: "Lenox Hill",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Lincoln Square",
        value: "Lincoln Square",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Lower East Side",
        value: "Lower East Side",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Meatpacking District",
        value: "Meatpacking District",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Midtown East",
        value: "Midtown East",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Midtown Manhattan",
        value: "Midtown Manhattan",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Midtown South",
        value: "Midtown South",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Midtown West",
        value: "Midtown West",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Morningside Heights",
        value: "Morningside Heights",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Murray Hill",
        value: "Murray Hill",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "NoMad",
        value: "NoMad",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "SoHo",
        value: "SoHo",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "South Village",
        value: "South Village",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Theater District",
        value: "Theater District",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Tribeca",
        value: "Tribeca",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Two Bridges",
        value: "Two Bridges",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "West Village",
        value: "West Village",
        subtitle: "Manhattan, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Manhattan" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      //Queens
      {
        label: "Astoria",
        value: "Astoria",
        subtitle: "Queens, NY",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.NY },
          { dataKey: "areas", value: "Queens" },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      //Los Angeles
      {
        label: "Central LA",
        value: "Central LA",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Century City",
        value: "Century City",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Downtown Los Angeles",
        value: "Downtown Los Angeles",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Hollywood",
        value: "Hollywood",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Melrose",
        value: "Melrose",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Palms",
        value: "Palms",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "South Los Angeles",
        value: "South Los Angeles",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Sunset Strip",
        value: "Sunset Strip",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Tropico",
        value: "Tropico",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Venice",
        value: "Venice",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "West Los Angeles",
        value: "West Los Angeles",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
      {
        label: "Westchester",
        value: "Westchester",
        subtitle: "Los Angeles",
        filterUsing: [
          { dataKey: "regions", value: BlaceV2Type.Regions.LA },
          { dataKey: "dataType", value: "venue" },
        ],
      },
    ],
  },
  capacity: {
    dataKey: "capacity",
    property: "capacity/max",
    propertyType: "number",
    title: "Capacity",
    type: "value-ge",
    filterUsingKeys: ["dataType"],
  },
  vendorType: {
    dataKey: "vendorType",
    title: "Vendor Type",
    type: "single-choice",
    property: "categories",
    propertyType: "simple-array",
    startOpen: false,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "Catering",
        value: "catering",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Photo & Video",
        value: "photo-video",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Entertainment",
        value: "entertainment",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Staffing",
        value: "staffing",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Floral & Decor",
        value: "decor",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "AV & Lighting",
        value: "av-lightning",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Event Production",
        value: "event-production",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Rentals",
        value: "rentals",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Event Planner",
        value: "event-planner",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Experiential",
        value: "experiential",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "DMC",
        value: "dmc",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
    ],
  },
  venueType: {
    dataKey: "venueType",
    title: "Venue Type",
    type: "multi-choice",
    property: "categories",
    propertyType: "simple-array",
    startOpen: true,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "Amenity Space",
        value: "amenity space",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Athletic Facility",
        value: "athletic facility",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Banquet Hall/Ballroom",
        value: "banquet hall/ballroom",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Bar",
        value: "bar",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Commercial Kitchen",
        value: "commercial kitchen",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Commercial Office",
        value: "commercial office",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Condo",
        value: "condo",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Conference Room",
        value: "conference room",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Gallery",
        value: "gallery",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Landmark",
        value: "landmark",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Loft",
        value: "loft",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Museum",
        value: "museum",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Music Venue",
        value: "music venue",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Outdoor Space",
        value: "outdoor space",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Pool",
        value: "pool",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Production Studio",
        value: "production studio",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Raw Space",
        value: "raw space",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Restaurant",
        value: "restaurant",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Rooftop",
        value: "rooftop",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Screening Room",
        value: "screening room",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Storefront",
        value: "storefront",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Studio",
        value: "studio",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Townhome",
        value: "townhome",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Turn Key Venue",
        value: "turn key venue",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Warehouse",
        value: "warehouse",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Water Tower",
        value: "water tower",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "White Box",
        value: "white box",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
    ],
  },
  bestUsedFor: {
    dataKey: "bestUsedFor",
    title: "Best Used For",
    type: "single-choice",
    property: "attributes/attributeId",
    propertyType: "complex-array",
    startOpen: true,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "Art Exhibition",
        value: "art-exhibition",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Brand Activation",
        value: "brand-activation",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Ceremony",
        value: "ceremony",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Concert",
        value: "concert",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Corporate Dinner",
        value: "corporate-dinner",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Corporate Party",
        value: "corporate-party",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Experiential",
        value: "experiential",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Fashion",
        value: "fashion",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Film/Photo",
        value: "filmphoto",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Gala",
        value: "gala",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Gallery",
        value: "gallery",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Holiday Party",
        value: "holiday-party",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Lectures & Discussions",
        value: "lectures-and-discussions",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Meeting",
        value: "meeting",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Mitzvahs",
        value: "mitzvahs",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Networking Space",
        value: "networking-space",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Press Event",
        value: "press-event",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Public Talk",
        value: "public-talk",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Product Launch",
        value: "product-launch",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Private Chef",
        value: "private-chef",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Screening",
        value: "screening",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Showroom",
        value: "showroom",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Social Event",
        value: "social-event",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Wedding",
        value: "wedding",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
    ],
  },
  amenities: {
    dataKey: "amenities",
    title: "Amenities",
    type: "multi-choice",
    property: "attributes/attributeId",
    propertyType: "complex-array",
    startOpen: true,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "A/V Capabilities",
        value: "av-capabilities",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "ADA Accessible",
        value: "ada-accessible",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Back of House Space",
        value: "back-of-house-space",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Column Free",
        value: "column-free",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Elevator Access",
        value: "elevator-access",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Furnished",
        value: "furnished",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Gender Neutral Bathrooms",
        value: "gender-neutral-restrooms",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "High Ceilings",
        value: "high-ceilings",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "HVAC",
        value: "hvac",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Kitchen",
        value: "kitchen",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Outdoor Space",
        value: "outdoor-space",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Rooftop",
        value: "rooftop",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Storefront",
        value: "storefront",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Views",
        value: "views",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
      {
        label: "Wifi",
        value: "wifi",
        filterUsing: [{ dataKey: "dataType", value: "venue" }],
      },
    ],
  },
  specialFeatures: {
    dataKey: "specialFeatures",
    title: "Vendor Features",
    type: "multi-choice",
    property: "attributes/attributeId",
    propertyType: "complex-array",
    startOpen: true,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "Award Winning",
        value: "award-winning",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Buffet",
        value: "buffet",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Culinary Brilliance",
        value: "culinary-brilliance",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Custom Designed Albums",
        value: "custom-designed-albums",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Drag Performers",
        value: "drag-performers",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Drop-Off Catering",
        value: "drop-off-сatering",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Elevated Portraits",
        value: "elevated-portraits",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Gluten Free",
        value: "gluten-free",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Halal",
        value: "halal",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Hispanic or Latinx-owned",
        value: "hispanic-or-latinx-owned",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Innovative",
        value: "innovative",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Interactive",
        value: "interactive",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "LGBTQ+ Owned",
        value: "lgbtq-owned",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Live Entertainment",
        value: "live-entertainment",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Livestream Capabilities",
        value: "livestream-capabilities",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Michelin Star Chefs",
        value: "michelin-star-chefs",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Minority Owned",
        value: "minority-owned",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Next-Day Edits",
        value: "next-day-edits",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Organic",
        value: "organic",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Personalized Keepsakes",
        value: "personalized-keepsakes",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Same-Day Edits",
        value: "same-day-edits",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Tastings Offered",
        value: "tastings-offered",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Theme Driven",
        value: "theme-driven",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Vegan",
        value: "vegan",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Vegetarian",
        value: "vegetarian",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Videography Services",
        value: "videography-services",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Virtual Events",
        value: "virtual-events",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Weddings",
        value: "weddings",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Women-Owned",
        value: "women-owned",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "Zero Waste",
        value: "zero-waste",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
    ],
  },
  vendorPricing: {
    dataKey: "vendorPricing",
    title: "Vendor Pricing",
    type: "single-choice",
    property: "price/priceLevel",
    propertyType: "string",
    startOpen: false,
    filterUsingKeys: ["dataType"],
    options: [
      {
        label: "$",
        value: "1",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "$$",
        value: "2",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "$$$",
        value: "3",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
      {
        label: "$$$$",
        value: "4",
        filterUsing: [{ dataKey: "dataType", value: "vendor" }],
      },
    ],
  },
};

//Change the FILTERS to be {venue: {quickFilters: [], extraFilters: []}, vendor: {quickFilters: [], extraFilters: []} } when we remove old filters and have vendor filters
export const VENUE_LISTINGS_EXTRA_FILTERS = [
  "isExclusive",
  "venueType",
  "amenities",
  "areas",
  "neighborhood",
];

export const VENDOR_LISTINGS_EXTRA_FILTERS = [
 "specialFeatures"
];

export const VENUE_LISTINGS_QUICK_FILTERS = [
  "capacity",
  "bestUsedFor",
];

export const VENDOR_LISTINGS_QUICK_FILTERS = [
 "vendorPricing",
 "vendorType"
];

export const CLIENT_DASHBOARD_FILTERS: Record<string, AppSearchFilterType.AppSearchFilter> = {
  eventValue: {
    dataKey: "eventValue",
    property: "eventName",
    propertyType: "string",
    title: "Event",
    type: "single-choice",
  },
  startDateValue: {
    dataKey: "startDateValue",
    property: "startDate",
    propertyType: "date",
    title: "Start Date",
    type: "date-ge",
  },
  endDateValue: {
    dataKey: "endDateValue",
    property: "endDate",
    propertyType: "date",
    title: "End Date",
    type: "date-le",
  },
  statusFilters: {
    dataKey: "statusFilters",
    property: "inquiryStatus",
    propertyType: "object",
    title: "Status",
    type: "callback",
    callback: (expression: string, propertyData: StatusFilters) => {
      const { open, completed, closed, booked } = propertyData;
      const openStatus = "draft|submitted|responded";
      const completedStatus = "completed";
      const closedStatus = "closed";
      const bookedStatus = "booked";

      if ((open && completed && closed && booked) || (!open && !completed && !closed && !booked)) {
        return "";
      }

      const delimiter = "|";
      let statusesList = "";
      if (open) {
        statusesList = !statusesList ? openStatus : statusesList + delimiter + openStatus;
      }
      if (completed) {
        statusesList = !statusesList ? completedStatus : statusesList + delimiter + completedStatus;
      }
      if (closed) {
        statusesList = !statusesList ? closedStatus : statusesList + delimiter + closedStatus;
      }
      if (booked) {
        statusesList = !statusesList ? bookedStatus : statusesList + delimiter + bookedStatus;
      }

      return `search.in(${expression},'${statusesList}','${delimiter}')`;
    },
  },
};
