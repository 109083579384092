import { Dispatch, SetStateAction, useMemo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import LeftIconCollapse from "@/public/icons/arrowLeft.svg";
import RightIconCollapse from "@/public/icons/arrowRight.svg";
import { BaseButton } from "@/src/component/base";
import { ClosedInquiryBanner } from "@/src/component/view/Dashboard/component/MainContainer/components/ClosedInquiryBanner";
import { MiddlePanelTabs } from "@/src/component/view/Dashboard/component/MainContainer/components/MiddlePanelTabs";
import { useBreakPointUp } from "@/src/hook";
import { InquirySearchType, InquiryStatusLabels } from "@/src/type/blaceV2";
import styles from "./Maincontainer.module.scss";

interface MainContainerProps {
  selectedInquiry: InquirySearchType | null;
  isFAQDesktopOpen: boolean;
  isLeftContainerCollapsed: boolean;
  isRightContainerCollapsed: boolean;
  setIsLeftContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setIsRightContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  setSelectedInquiry: Dispatch<SetStateAction<InquirySearchType | null>>;
  setInitialInquiryId: Dispatch<SetStateAction<string>>;
}

function MainContainer({
  selectedInquiry,
  isFAQDesktopOpen,
  isLeftContainerCollapsed,
  isRightContainerCollapsed,
  setIsLeftContainerCollapsed,
  setIsRightContainerCollapsed,
  setSelectedInquiry,
  setInitialInquiryId,
}: MainContainerProps) {
  const isDesktop = useBreakPointUp("md");

  const onClickCollapseHandlerLeft = () => {
    setIsLeftContainerCollapsed(!isLeftContainerCollapsed);
  };
  const onClickCollapseHandlerRight = () => {
    setIsRightContainerCollapsed(!isRightContainerCollapsed);
  };

  const handleBack = () => {
    setSelectedInquiry(null);
    setInitialInquiryId("");
  };

  const collapseLeftButtonStyles = useMemo(() => {
    return isFAQDesktopOpen
      ? [`${styles.buttonCollapseLeft} ${styles.buttonCollapseFaqOpen}`]
      : [styles.buttonCollapseLeft];
  }, [isFAQDesktopOpen]);

  const collapseRightButtonStyles = useMemo(() => {
    return isFAQDesktopOpen
      ? [`${styles.buttonCollapseRight} ${styles.buttonCollapseFaqOpen}`]
      : [styles.buttonCollapseRight];
  }, [isFAQDesktopOpen]);

  return (
    <div className={styles.mainWrapper}>
      {isDesktop && (
        <BaseButton
          className={cn(collapseLeftButtonStyles)}
          onClick={onClickCollapseHandlerLeft}
        >
          <Image
            src={isLeftContainerCollapsed ? RightIconCollapse : LeftIconCollapse}
            alt="collapse icon"
          />
        </BaseButton>
      )}

      {selectedInquiry && (
        <div className={styles.mainContainerHeader}>
          {!isDesktop && (
            <BaseButton onClick={handleBack} className={styles.backButton}>
              <ArrowBackIcon sx={{ color: "#000" }} />
            </BaseButton>
          )}
          <div className={styles.titleLine}>
            <Typography variant="h3" className={styles.inquiryTitle}>
              {selectedInquiry?.searchItem?.title}
            </Typography>
            {selectedInquiry.inquiryStatus === InquiryStatusLabels.Closed && (
              <ClosedInquiryBanner
                closedReason={selectedInquiry.closedLostReason}
              />
            )}
          </div>
        </div>
      )}

      <MiddlePanelTabs selectedInquiry={selectedInquiry} />

      {isDesktop ? (
        <BaseButton
          className={cn(collapseRightButtonStyles)}
          onClick={onClickCollapseHandlerRight}
        >
          <Image
            src={isRightContainerCollapsed ? LeftIconCollapse : RightIconCollapse}
            alt="collapse icon"
          />
        </BaseButton>
      ) : null}
    </div>
  );
}

export default MainContainer;
