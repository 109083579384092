import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import cn from "classnames";
import Image from "next/image";
import Link from "next/link";
import LeftIconCollapse from "@/public/icons/arrowLeft.svg";
import CalendarIcon from "@/public/icons/calendarIcon.svg";
import EmailFillIcon from "@/public/icons/emailFillIcon.svg";
import OutIcon from "@/public/icons/outIcon.svg";
import PersonIcon from "@/public/icons/personIcon.svg";
import PhoneIcon from "@/public/icons/phoneIcon.svg";
import PlaceIcon from "@/public/icons/placeIcon.svg";
import {
  BaseButton,
  CopyButton,
  EmailLink,
  ImgFromCDN,
  PhoneLink,
} from "@/src/component/base";
import { useCMS } from "@/src/component/provider";
import { IconBlock } from "@/src/component/view/Dashboard/component/IconBlock";
import { PaymentSummary } from "@/src/component/view/Dashboard/component/PaymentSummary";
import { RightSidebarAccordion } from "@/src/component/view/Dashboard/component/RightSidebarAccordion";
import { useBreakPointDown, useBreakPointUp } from "@/src/hook";
import { ImageLogic, VendorLogic, VenueLogic } from "@/src/model";
import { InquirySearchType } from "@/src/type/blaceV2";
import { FormattedDateHelper, Log, StringHelper } from "@/src/util";
import styles from "./RightContainer.module.scss";

interface RightContainerProps {
  isRightContainerCollapsed: boolean;
  setIsRightContainerCollapsed: Dispatch<SetStateAction<boolean>>;
  inquiry: InquirySearchType | null;
}

const initialOpenPanels = {
  businessInfo: true,
  eventInfo: true,
  businessContact: true,
};

const initialClosedPanels = {
  businessInfo: false,
  eventInfo: false,
  businessContact: true,
};

enum Pannels {
  BUSINESS_INFO = "businessInfo",
  EVENT_INFO = "eventInfo",
  BUSINESS_CONTACT = "businessContact",
}

enum PannelsTitle {
  BUSINESS_INFO_VENUE = "VENUE",
  BUSINESS_INFO_VENDOR = "VENDOR",
  EVENT_INFO = "EVENT",
  VENUE_CONTACT = "VENUE CONTACT",
  VENDOR_CONTACT = "VENDOR CONTACT",
}

function RightContainer({
  isRightContainerCollapsed,
  setIsRightContainerCollapsed,
  inquiry,
}: RightContainerProps) {
  const isSmallerDesktop = useBreakPointDown("lg");
  const isLargerMobile = useBreakPointUp("md");
  const isMediumToLargeDesktop = isLargerMobile && isSmallerDesktop;
  const cms = useCMS();
  const { contactPerson, searchItem } = inquiry ?? {};
  const [isCollapseButtonVisible, setIsCollapseButtonVisible] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState<{
    [key: string]: boolean;
  }>(initialOpenPanels);

  const isVenue =
    inquiry?.inquiryType?.toUpperCase() === PannelsTitle.BUSINESS_INFO_VENUE;

  const fullInfoLink = searchItem
    ? cms.constructLink(`/${searchItem.dataType}/${searchItem.slug}`)
    : "";

  const eventStartDate = inquiry?.startDate
    ? FormattedDateHelper.formatExtendedDate(inquiry.startDate)
    : "";

  const eventEndDate = inquiry?.endDate
    ? FormattedDateHelper.formatExtendedDate(inquiry.endDate)
    : "";

  const mockInquiry = {
    payment: {
      total: 50000,
      paid: 10000,
      outstanding: 30000,
      next: "5,000",
      dueDate: "Nov 7, 2023",
      isCanceled: false,
      totalPayments: 5,
      paymentsCompleted: 1,
    },
  };

  const getDisplayAddress = () => {
    return isVenue
      ? VenueLogic.getVenueAddressForDisplay(searchItem?.locations)
      : VendorLogic.getVendorAddressForDisplay(searchItem?.regions);
  };

  const onClickCollapseHandler = () => {
    setIsRightContainerCollapsed(!isRightContainerCollapsed);
  };

  const handleChange = (panel: string) => {
    setExpandedPanels(prevExpandedPanels => ({
      ...prevExpandedPanels,
      [panel]: !prevExpandedPanels[panel],
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapseButtonVisible(isRightContainerCollapsed);
    }, 300);

    return () => clearTimeout(timer);
  }, [isRightContainerCollapsed]);

  useEffect(() => {
    const panelsState = isMediumToLargeDesktop
      ? initialClosedPanels
      : initialOpenPanels;
    setExpandedPanels(panelsState);
  }, [isMediumToLargeDesktop]);

  const searchItemMainImageUrl = useMemo(() => {
    return searchItem ? ImageLogic.getMainImageUrl(searchItem) : null;
  }, [searchItem]);

  const contactTitle = useMemo(() => {
    return isVenue ? PannelsTitle.VENUE_CONTACT : PannelsTitle.VENDOR_CONTACT;
  }, [isVenue]);

  const isExclusive = inquiry?.isExclusive || false;
  const contactEmail = useMemo(() => {
    return isExclusive && !!inquiry?.ownerCrmEmail?.length ? inquiry?.ownerCrmEmail : contactPerson?.emailProxy;
  }, [isExclusive, inquiry, contactPerson]);
  const contactName = useMemo(() => {
    return isExclusive ? (inquiry?.ownerCrmDisplayName ?? inquiry?.searchItem?.title) : contactPerson?.name;
  }, [isExclusive, inquiry, contactPerson]);
  const contactPhone = useMemo(() => {
    return isExclusive ? inquiry?.ownerCrmPhoneNumber : contactPerson?.phone;
  }, [isExclusive, inquiry, contactPerson]);

  if (!contactName) {
    Log.logToDataDog(
      Log.LogLevel.WARN,
      "RightContainer.tsx",
      "DisplayContactError",
      [{
        inquiryId: inquiry?.inquiryId,
        isExclusive,
      }],
      "The contact person name is missing."
    );
  }
  if (!contactEmail) {
    Log.logToDataDog(
        Log.LogLevel.WARN,
        "RightContainer.tsx",
        "DisplayContactError",
        [{
          inquiryId: inquiry?.inquiryId,
          isExclusive,
        }],
        "The contact person email is missing."
    );
  }

  return (
    <div className={styles.rightSidebarWrapper}>
      {isCollapseButtonVisible && (
        <BaseButton
          className={styles.buttonRightSidebarCollapse}
          onClick={onClickCollapseHandler}
        >
          <Image src={LeftIconCollapse} alt="collapse icon" />
        </BaseButton>
      )}
      {inquiry && (
        <>
          <RightSidebarAccordion
            isExpanded={expandedPanels.businessInfo}
            onChange={handleChange}
            id={Pannels.BUSINESS_INFO}
            title={
              isVenue
                ? PannelsTitle.BUSINESS_INFO_VENUE
                : PannelsTitle.BUSINESS_INFO_VENDOR
            }
          >
            <AccordionDetails className={styles.accordionDetails}>
              <Box
                className={cn(
                  styles.panelDetailsWrapper,
                  styles.marginBottomMd,
                  styles.title
                )}
              >
                {searchItemMainImageUrl && (
                  <ImgFromCDN
                    src={searchItemMainImageUrl}
                    alt="business photo"
                    width={40}
                    height={40}
                    className={styles.businessImage}
                  />
                )}
                <Typography variant="h4" className={styles.panelDetailsTitle}>
                  {searchItem?.title}
                </Typography>
              </Box>
              <Box className={styles.marginBottomSm}>
                <IconBlock icon={PlaceIcon}>{getDisplayAddress()}</IconBlock>
              </Box>
              <Link
                className={styles.panelDetailsWrapper}
                href={{
                  pathname: fullInfoLink,
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Image
                  src={OutIcon}
                  alt="out icon"
                  width="16"
                  height="16"
                  className={styles.businessImage}
                />
                <Typography variant="subtitle1" className={styles.link}>
                  View listing
                </Typography>
              </Link>
            </AccordionDetails>
          </RightSidebarAccordion>
          <Divider />
          <RightSidebarAccordion
            isExpanded={expandedPanels.eventInfo}
            onChange={handleChange}
            id={Pannels.EVENT_INFO}
            title={PannelsTitle.EVENT_INFO}
          >
            <AccordionDetails className={styles.accordionDetails}>
              <Typography
                variant="h4"
                className={cn(styles.panelDetailsTitle, styles.marginBottomMd)}
              >
                {inquiry.eventName}
              </Typography>
              <Box className={styles.marginBottomSm}>
                <IconBlock icon={PersonIcon}>{inquiry.guests} guests</IconBlock>
              </Box>
              <IconBlock
                icon={CalendarIcon}
              >{`${eventStartDate} - ${eventEndDate}`}</IconBlock>
              {inquiry.flexibleDates && (
                <IconBlock>Dates are flexible</IconBlock>
              )}
            </AccordionDetails>
          </RightSidebarAccordion>
          <Divider />
          <RightSidebarAccordion
            isExpanded={expandedPanels.businessContact}
            onChange={handleChange}
            id={Pannels.BUSINESS_CONTACT}
            title={contactTitle}
          >
            <AccordionDetails className={styles.accordionDetails}>
              <Box
                className={cn(
                  styles.panelDetailsWrapper,
                  styles.marginBottomMd,
                  styles.title
                )}
              >
                {contactPerson?.photo ? (
                  <Image
                    src={contactPerson.photo}
                    alt="manager photo"
                    width="40"
                    height="40"
                    className={styles.businessImage}
                    data-testid="managerPhoto"
                  />
                ) : (
                  <div className={styles.userInitial}>
                    <p className={styles.userInitialText}>
                      {StringHelper.getInitialLetters(
                        contactName || ""
                      )}
                    </p>
                  </div>
                )}
                <Typography variant="h4" className={styles.panelDetailsTitle}>
                  {contactName}
                </Typography>
              </Box>
              {contactPhone && (
                <Box className={styles.marginBottomSm}>
                  <IconBlock icon={PhoneIcon}>
                    <PhoneLink phoneNumber={contactPhone} />
                  </IconBlock>
                </Box>
              )}
              {contactEmail && (
                <div className={styles.managerEmail}>
                  <IconBlock icon={EmailFillIcon}>
                    <EmailLink email={contactEmail || ""} />
                  </IconBlock>
                  <Box className={styles.copyButtonContainer}>
                    <CopyButton
                      textToCopy={contactEmail}
                      hasOnlyIcon={isMediumToLargeDesktop}
                    />
                  </Box>
                </div>
              )}
            </AccordionDetails>
          </RightSidebarAccordion>
          <Divider />
          {false && mockInquiry.payment && (
            <PaymentSummary payment={mockInquiry.payment} />
          )}
        </>
      )}
    </div>
  );
}

export default RightContainer;
