import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider, FormControlLabel, Switch, Typography } from "@mui/material";
import cn from "classnames";
import { BaseButton, BaseModal } from "@/src/component/base";
import { FilterPanelSectionV2 } from "@/src/component/partial/FilterPanelSectionV2";
import { useApp } from "@/src/component/provider";
import { VENDOR_LISTINGS_EXTRA_FILTERS, VENUE_LISTINGS_EXTRA_FILTERS } from "@/src/const";
import { SearchLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import styles from "./ExtraFiltersModal.module.scss";

interface ExtraFiltersModalProps {
  onClose: () => void;
}

function ExtraFiltersModal({ onClose }: ExtraFiltersModalProps) {
  const app = useApp();
  const isModalOpen = app.showExtraFilters;
  const currentListingsType = useMemo(
    () => app.searchFilters?.filterState.dataType?.[0],
    [app.searchFilters?.filterState.dataType],
  );

  const [isExclusiveSelected, setIsExclusiveSelected] = useState(false);
  const [selectedTagValues, setSelectedTagValues] = useState<string[]>([]);

  const filtersStateRef = useRef<Record<string, string[]>>({});

  const filterSections =
    currentListingsType === BlaceV2Type.SearchTypes.Venue
      ? VENUE_LISTINGS_EXTRA_FILTERS
      : VENDOR_LISTINGS_EXTRA_FILTERS;

  const handleApplyFilters = () => {
    const updatedFilters = { ...filtersStateRef.current };
    if (currentListingsType === BlaceV2Type.SearchTypes.Venue) {
      updatedFilters.isExclusive = isExclusiveSelected ? [`${isExclusiveSelected}`] : [];
    }
    app.searchFilters?.setMultiChoiceV2(updatedFilters);
    SearchLogic.trackFilterUsed(JSON.stringify(updatedFilters), "extraFiltersModal", "ExtraFiltersModal.tsx");
    onClose();
  };

  const clearFilters = (filters: string[]) => {
    const clearedFilters = filters.reduce((filter, value) => ({ ...filter, [value]: [] }), {});
    app.searchFilters?.setMultiChoiceV2(clearedFilters);
    // the empty object was used instead of `clearedFilters` to have an ability to find the clear logic
    SearchLogic.trackFilterUsed(JSON.stringify({}), "extraFiltersModal", "ExtraFiltersModal.tsx");
    filtersStateRef.current = {};
  };

  const handleClearAll = () => {
    clearFilters(filterSections);
    onClose();
  };

  // it's needed to load the filters state from URL on page first load
  useEffect(() => {
    const urlFiltersForRef: Record<string, string[]> = {};
    let urlFiltersForState: string[] = [];
    filterSections.forEach(
      (filter) => {
        if (SearchLogic.FILTERS[filter]?.noTags) {
          return;
        }
        const dataKey = SearchLogic.FILTERS[filter]?.dataKey;
        const appliedTags: string[] = app.searchFilters?.getFilterData(dataKey) ?? [];
        if (appliedTags && filtersStateRef?.current[dataKey] === undefined) {
          urlFiltersForRef[dataKey] = appliedTags;
          urlFiltersForState = [...urlFiltersForState, ...(appliedTags.map((tagValue: string) => `${dataKey}-${tagValue}`))];
        }
      }
    );

    setSelectedTagValues(urlFiltersForState);
    filtersStateRef.current = { ...filtersStateRef.current, ...urlFiltersForRef };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentListingsType === BlaceV2Type.SearchTypes.Venue) {
      const isExclusive = app.searchFilters?.getFilterData("isExclusive");
      setIsExclusiveSelected(!!isExclusive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.searchFilters?.filterState]);

  useEffect(() => {
    if (currentListingsType) {
      filtersStateRef.current = {};
    }
  }, [currentListingsType]);

  return (
    <BaseModal
      dialogProps={{ open: isModalOpen, onClose }}
      onExit={onClose}
      additionalClassName={styles.extraFiltersModal}
    >
      <Typography className={styles.modalTitle}>Filters</Typography>
      <Divider className={styles.titleDivider} />
      <Box className={styles.modalContent}>
        {currentListingsType === BlaceV2Type.SearchTypes.Venue && (
          <FormControlLabel
            control={
              <Switch
                checked={isExclusiveSelected}
                onChange={(e) => setIsExclusiveSelected(e.target.checked)}
                className={styles.switchExclusive}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            className={styles.exclusiveControl}
            label={
              <Typography component="p" className={styles.exclusiveTag}>
                BLACE Exclusive
              </Typography>
            }
          />
        )}
        {filterSections.map(
          (filter) =>
            !SearchLogic.FILTERS[filter]?.noTags && (
              <FilterPanelSectionV2
                key={SearchLogic.FILTERS[filter]?.dataKey}
                {...SearchLogic.FILTERS[filter]}
                filtersStateRef={filtersStateRef}
                selectedTagValues={selectedTagValues}
                setSelectedTagValues={setSelectedTagValues}
              />
            ),
        )}
      </Box>
      <Box className={styles.actionButtons}>
        <BaseButton className={cn(styles.actionButton, styles.clear)} onClick={handleClearAll}>
          Clear all
        </BaseButton>
        <BaseButton className={cn(styles.actionButton, styles.apply)} onClick={handleApplyFilters}>
          Apply filters
        </BaseButton>
      </Box>
    </BaseModal>
  );
}

export default ExtraFiltersModal;
