export function constructInquiriesQuery(
  value: string,
  inquiriesPerPageAmount: number,
  requestInquiriesSkip: number,
  filterValue: string,
  sessionId?: string
) {
  const defaultFilter = buildInquiriesTypeDefaultFilter();

  const query = {
    count: true,
    search: value,
    queryType: "full",
    searchMode: "all",
    select: "*",
    searchFields: "eventName, searchItem/title, searchItem/description, notes",
    top: inquiriesPerPageAmount,
    skip: requestInquiriesSkip,
    orderby: "lastActivityDate desc, createDate desc",
    filter: filterValue ? filterValue + " and " + defaultFilter : defaultFilter,
    sessionId,
  };

  return query;
}

function buildInquiriesTypeDefaultFilter(): string {
  const delimeter = ",";
  const filterType = "inquiryType";
  const values = ["vendor", "venue"];

  const defaultFilter = `search.in(${filterType}, '${values.join(
    delimeter
  )}', '${delimeter}')`;

  return defaultFilter;
}
