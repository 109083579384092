import { useCallback } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import type { ReadonlyURLSearchParams } from "next/navigation";
import type { NextRouter } from "next/router";

type UseNextNavigationType = {
  createQueryString: (name: string, value: string) => string;
  updateQueryString: (name: string, value: string) => void;
  getQueryParam: (name: string) => string | null | undefined;
  getInitialQueryParam: (name: string) => string | null | undefined;
  getAndValidateInitialQueryParam: <E, K extends string>(
    enumDef: { [key in K]: E }, // eslint-disable-line @typescript-eslint/no-unused-vars
    name: string
  ) => E | undefined;
  updateQueryStringBulk: (data: { name: string; value: string }[]) => void;
  searchParams: ReadonlyURLSearchParams;
  pathname: string;
  router: NextRouter;
};

export function useNextNavigation(): UseNextNavigationType {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const createQueryString = useCallback(
    (name: string, value: string): string => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);
      return params.toString();
    },
    [searchParams]
  );

  const updateQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);
      router.push(`${pathname}?${params.toString()}`, undefined, {
        shallow: true,
      });
    },
    [searchParams, pathname]
  );

  const updateQueryStringBulk = useCallback(
    (data: { name: string; value: string }[]) => {
      if ((data ?? []).length <= 0) {
        return;
      }
      const params = new URLSearchParams(searchParams.toString());

      data.map(opt => {
        // add filter to the url if value is selected
        if (opt.value) {
          params.set(opt.name, opt.value);
          return;
        }

        // delete filter from the url if value isn't selected but was setted before
        if (params.has(opt.name)) {
          params.delete(opt.name);
          return;
        }
      });

      router.push(`${pathname}?${params.toString()}`, undefined, {
        shallow: true,
      });
    },
    [searchParams, pathname]
  );

  const getQueryParam = useCallback(
    (name: string): string | null | undefined => {
      const params = new URLSearchParams(searchParams.toString());
      return params.get(name);
    },
    [searchParams]
  );

  const getInitialQueryParam = (name: string): string | null | undefined => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.href);
      return params.get(name);
    }
  };

  const getAndValidateInitialQueryParam = <E, K extends string>(
    enumDef: { [key in K]: E }, // eslint-disable-line @typescript-eslint/no-unused-vars
    name: string
  ): E | undefined => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.href);
      const value = params.get(name);
      if (value && value in enumDef) {
        return enumDef[value as K] as E;
      }
    }
    return undefined;
  };

  return {
    router,
    pathname,
    searchParams,
    createQueryString,
    updateQueryString,
    updateQueryStringBulk,
    getQueryParam,
    getInitialQueryParam,
    getAndValidateInitialQueryParam,
  };
}
