export function hasData(str?: string | null) {
  return !(
    typeof str !== "string" ||
    !str ||
    (str ?? "").length === 0 ||
    (str ?? "").replaceAll(" ", "").length === 0
  );
}

export function capitalize(str?: string) {
  if (!str) {
    return;
  }
  return str[0].toUpperCase() + str.substring(1);
}

export function reverseCamelCase(str?: string) {
  if (!str) {
    return;
  }
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
}

export function checkIsStringOrIsNull(toCheck: any, isNull: string): string {
  return (typeof toCheck === "string" ? toCheck : undefined) ?? isNull;
}

export function getInitialLetters(fullText: string) {
  const firstLetters = fullText
    .split(" ")
    .map((word: string) => word.charAt(0))
    .join("");

  if (firstLetters.length > 2) {
    return firstLetters.slice(0, 2);
  }
  return firstLetters;
}

export function trim(fullText: string): string {
  return fullText.replace(/^[\s\n]*/gm, "").replace(/[\n\s]*$/gm, "");
}

export function formatFileSize(sizeInBytes: number) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (sizeInBytes < kilobyte) {
    return sizeInBytes + " B";
  } else if (sizeInBytes < megabyte) {
    return (sizeInBytes / kilobyte).toFixed(2) + " KB";
  } else if (sizeInBytes < gigabyte) {
    return (sizeInBytes / megabyte).toFixed(2) + " MB";
  }
  return (sizeInBytes / gigabyte).toFixed(2) + " GB";
}

// takes a phone number in the format "+1 (xxx) xxx-xxxx" and returns it in the format "xxxxxxxxxx"
export function cleanPhoneNumber(phoneNumber: string) {
  const formattedNumber = phoneNumber.replace(/\D/g, "");
  
  if (formattedNumber.length === 11 && formattedNumber[0] === "1") {
      return formattedNumber.substring(1);
  }
  
  return formattedNumber || null;
}

export const formatPhoneNumber = (input: string) => {
  if (input === "+") {
    return "+";
  }
  const cleaned = input.replace(/\D/g, "");
  if (cleaned === "1") {
    return "+1";
  }
  const cleanedWithoutLeadingOne = cleaned.startsWith("1")
    ? cleaned.slice(1)
    : cleaned;

  switch (true) {
    case cleanedWithoutLeadingOne.length === 0:
      return "";
    case cleanedWithoutLeadingOne.length <= 3:
      return `+1 (${cleanedWithoutLeadingOne}`;
    case cleanedWithoutLeadingOne.length <= 6:
      return `+1 (${cleanedWithoutLeadingOne.slice(0, 3)}) ${cleanedWithoutLeadingOne.slice(3)}`;
    case cleanedWithoutLeadingOne.length <= 10:
      return `+1 (${cleanedWithoutLeadingOne.slice(0, 3)}) ${cleanedWithoutLeadingOne.slice(3, 6)}-${cleanedWithoutLeadingOne.slice(6)}`;
    default:
      return `+1 (${cleanedWithoutLeadingOne.slice(0, 3)}) ${cleanedWithoutLeadingOne.slice(3, 6)}-${cleanedWithoutLeadingOne.slice(6, 10)}`;
  }
};
