import { useContext, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import cn from "classnames";
import Image from "next/image";
import CompleteIcon from "@/public/icons/completeIcon.svg";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import InquirySecondaryStatusLabel from "@/src/component/view/Dashboard/component/LeftContainer/components/InquirySecondaryStatusLabel/InquirySecondaryStatusLabel";
import InquiryStatusLabel from "@/src/component/view/Dashboard/component/LeftContainer/components/InquiryStatusLabel/InquiryStatusLabel";
import { useScrollToInquiry } from "@/src/hook";
import { InquiryStatusLabels } from "@/src/type/blaceV2/BlaceV2Enum";
import { InquirySearchType } from "@/src/type/blaceV2/BlaceV2Type";
import { FormattedDateHelper } from "@/src/util";
import styles from "./Event.module.scss";

interface EventProps {
  inquiry: InquirySearchType;
  selectedInquiryId?: string;
  onInquiryItemClick: (id: string) => void;
}

function Event({ inquiry, selectedInquiryId, onInquiryItemClick }: EventProps) {
  const [isMouseEnterToEvent, setIsMouseEnterToEvent] = useState(false);

  const dashboardContext = useContext(DashboardContext);

  const isCompleted = inquiry.inquiryStatus === InquiryStatusLabels.Сompleted;
  const isSelected = inquiry.inquiryId === selectedInquiryId;

  const scrollOffset = 150;
  const { eventRef } = useScrollToInquiry({isSelected, scrollOffset, searchId: dashboardContext.searchId || ""});

  const handleMouseEnter = () => {
    setIsMouseEnterToEvent(true);
  };

  const handleMouseLeave = () => {
    setIsMouseEnterToEvent(false);
  };

  const lastUpdateDate = useMemo(() => {
    const thisDate: number | undefined = inquiry.updateDate ?? inquiry.lastDataUpdateDate;
    return !thisDate
      ? undefined
      : isMouseEnterToEvent
        ? FormattedDateHelper.formatGlobalDate(thisDate)
        : FormattedDateHelper.formatRelativeDate(thisDate);
  }, [inquiry.updateDate, inquiry.lastDataUpdateDate, isMouseEnterToEvent]);

  return (
    <div
      className={cn(
        isSelected ? [`${styles.inquiryItem} ${styles.active}`] : [styles.inquiryItem]
      )}
      onClick={() => onInquiryItemClick(inquiry.inquiryId)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={inquiry.inquiryId}
      data-testid="event"
      ref={eventRef}
    >
      <div className={styles.inquiryInfo}>
        <Typography className={styles.inquiryType}>
          {inquiry.inquiryType} &#x2022; {inquiry.searchItem?.title}
        </Typography>
      </div>
      <div className={styles.eventNameWrapper}>
        {isCompleted && (
          <Image
            src={CompleteIcon}
            alt="complete icon"
            className={styles.icon}
          />
        )}
        <Typography variant="h5" className={styles.inquiryTitle}>
          {inquiry.eventName}
        </Typography>
      </div>
      <Typography className={styles.dataTime}>
        {FormattedDateHelper.formatExtendedDate(inquiry.startDate)} -{" "}
        {FormattedDateHelper.formatExtendedDate(inquiry.endDate)}
      </Typography>
      <div className={styles.cardBottomWrapper}>
        <div className={styles.buttonsWrapper}>
          <InquiryStatusLabel inquiryStatus={inquiry.inquiryStatus} />
          <InquirySecondaryStatusLabel
            inquiryStatus={inquiry.inquirySecondaryStatus}
          />
        </div>
        {lastUpdateDate && (
          <div className={styles.amountDaysContainer}>
            <Typography className={styles.amountDaysPayment}>
              Last updated:
            </Typography>
            <Typography className={styles.amountDaysPayment}>
              {lastUpdateDate}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default Event;
