import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { DashboardLayout } from "@/src/component/layout/DashboardLayout";
import { CLIENT_DASHBOARD_FILTERS } from "@/src/const";
import { useBreakPointDown, useNextNavigation } from "@/src/hook";
import {
  ActivityLogic,
  AuthLogic,
  CMSLogic,
  DashboardLogic,
  ImageLogic,
  SearchLogic,
  URLFilterLogic,
} from "@/src/model";
import { BlaceV2API } from "@/src/service";
import {
  DashboardUrlParamsType,
  Event,
  InquiryFiltersType,
  InquirySearchType,
} from "@/src/type/blaceV2/BlaceV2Type";
import { Log, StringHelper, uniqueId } from "@/src/util";
import styles from "./Dashboard.module.scss";
import { DashboardContext } from "./DashboardContext";
import { DashboardFallback } from "./component/DashboardFallback";
import { DashboardMobileManager } from "./component/DashboardMobileManager";
import { DrawerFAQContainer } from "./component/DrawerFAQContainer";
import { LeftContainer } from "./component/LeftContainer";
import { MainContainer } from "./component/MainContainer";
import { RightContainer } from "./component/RightContainer";

type UserInfo = {
  name: string;
  photo: string;
};

function Dashboard() {
  const [selectedInquiry, setSelectedInquiry] = useState<InquirySearchType | null>(null);
  const [inquiriesList, setInquiriesList] = useState<InquirySearchType[] | [] | undefined>([]);
  const [eventsList, setEventsList] = useState<Event[] | []>([]);
  const [isLeftContainerCollapsed, setIsLeftContainerCollapsed] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRightContainerCollapsed, setIsRightContainerCollapsed] = useState<boolean>(false);
  const [isFAQDesktopOpen, setIsFAQDesktopOpen] = useState<boolean>(false);
  const [isFAQMobileOpen, setIsFAQMobileOpen] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<InquiryFiltersType | undefined>(undefined);
  const [initialInquiryId, setInitialInquiryId] = useState<string>("");
  const [isURLWithQueryParams, setIsURLWithQueryParams] = useState<boolean>(true);
  const [isFetchingWithFilters, setIsFetchingWithFilters] = useState(false);
  const [totalRequestInquiriesAmount, setTotalRequestInquiriesAmount] = useState(0);
  const [requestInquiriesSkip, setRequestInquiriesSkip] = useState(0);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const inquiriesPerPageAmount = 10;
  const isMobile = useBreakPointDown("md");

  const router = useRouter();
  const nextNavigation = useNextNavigation();

  const isInquirySelected = Boolean(selectedInquiry);
  const isNoSearchMatchesInquiry = Boolean(searchValue && !inquiriesList?.length);
  const isNoFiltersMatchesInquiry = Boolean(appliedFilters && !inquiriesList?.length);
  const isErrorBySearch = Boolean(searchValue && isError);
  const isErrorByFiltering = Boolean(appliedFilters && isError);

  const isNoCreatedIquiries =
    !isFetching &&
    !isError &&
    !searchValue &&
    !appliedFilters &&
    !isFetchingWithFilters &&
    !inquiriesList?.length;

  const isFallbackVisible = isFetching || isNoCreatedIquiries || isError;

  const handleNewPage = (step: number) => {
    const skipAmount = (step - 1) * inquiriesPerPageAmount;
    setRequestInquiriesSkip(skipAmount);
  };

  const paginationCurrentPage = useMemo(() => {
    // prettier-ignore
    return requestInquiriesSkip
      ? Math.round(requestInquiriesSkip / inquiriesPerPageAmount + 1)
      : 1;
  }, [requestInquiriesSkip]);

  const paginationPagesAmount = useMemo(() => {
    return totalRequestInquiriesAmount
      ? Math.ceil(totalRequestInquiriesAmount / inquiriesPerPageAmount)
      : 0;
  }, [totalRequestInquiriesAmount]);

  const onClickContactSupport = () => {
    const messageId = uniqueId();
    ActivityLogic.toActivityService({
      action: "contactUs_cdash",
      actionId: messageId,
      actionIdType: "messageId",
      actionMessage: messageId,
      locationInApp: "Dashboard.tsx",
    });
    router.push(CMSLogic.constructLink("/inquiry/general"), undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const id = await ActivityLogic.SESSION_ID();
        setSessionId(id);
      } catch (error) {
        /**this doesn't matter */
      }
    })();
  }, []);

  useEffect(() => {
    const user = AuthLogic.getAuthCurrentUser();

    if (!user) {
      nextNavigation.router.push(CMSLogic.constructLink("/auth"));
    }

    if (!userInfo && user) {
      setUserInfo({ name: user.name, photo: user.photo_url || "" });
    }
  }, [nextNavigation.router]);

  useEffect(() => {
    searchValue && setRequestInquiriesSkip(0);
  }, [searchValue]);

  useEffect(() => {
    const queryParams = router.query as unknown as DashboardUrlParamsType;
    const { isURLWithFilterParams, filtersFromURL, inquiryId, page } =
      URLFilterLogic.getDashboardFiltersFromURL(queryParams);

    setIsURLWithQueryParams(isURLWithFilterParams);

    filtersFromURL && setAppliedFilters(filtersFromURL as unknown as InquiryFiltersType);
    inquiryId && setInitialInquiryId(inquiryId);

    if (page) {
      const inquiriesSkipAmount = inquiriesPerPageAmount * (page - 1);
      setRequestInquiriesSkip(inquiriesSkipAmount);
    }
  }, [router.asPath]);

  useEffect(() => {
    const fetchInquiries = async () => {
      if (isURLWithQueryParams && !appliedFilters) {
        return;
      }
      const tokenUser = AuthLogic.getIdentityToken();

      const filterValue = SearchLogic.constructFilter(appliedFilters, CLIENT_DASHBOARD_FILTERS);

      if (tokenUser) {
        setIsFetchingWithFilters(true);
        const value = SearchLogic.constructSearchTerm("full", searchValue);
        const query = DashboardLogic.constructInquiriesQuery(
          value,
          inquiriesPerPageAmount,
          requestInquiriesSkip,
          filterValue,
          sessionId,
        );
        try {
          const searchResponse =
            //@ts-ignore
            await BlaceV2API.InquiryServiceV2.postSearchQuery(query);

          const totalInquiriesAmount = searchResponse.body?.payload["@odata.count"];

          totalInquiriesAmount && setTotalRequestInquiriesAmount(Number(totalInquiriesAmount));
          const inquiries = searchResponse.body?.payload.value;
          inquiries && setInquiriesList(inquiries);
          setIsFetching(false);
          setIsFetchingWithFilters(false);
          if (!searchResponse.success) {
            setIsError(true);

            // prettier-ignore
            Log.logToDataDog(
              Log.LogLevel.ERROR,
              "Dashboard.tsx",
              "inquiryError",
              [searchResponse.body],
            );
          }
        } catch (error) {
          // prettier-ignore
          Log.logToDataDog(
            Log.LogLevel.ERROR,
            "Dashboard.tsx",
            "inquiryError",
            [error],
          );
          setIsFetching(false);
          setIsError(true);
          setIsFetchingWithFilters(false);
        }
      }
    };

    fetchInquiries();
  }, [searchValue, appliedFilters, isURLWithQueryParams, requestInquiriesSkip, sessionId]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventResponse = await BlaceV2API.InquiryServiceV2.getAllUserEvents();
        const events = eventResponse.body?.payload;
        events && setEventsList(events);
      } catch (error) {
        // prettier-ignore
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "Dashboard.tsx",
          "eventsError",
          [error]
        );
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    router.push(
      URLFilterLogic.constructDashboardURL(
        window.location.pathname,
        selectedInquiry,
        appliedFilters,
        paginationCurrentPage,
      ),
      undefined,
      { shallow: true },
    );
  }, [appliedFilters, paginationCurrentPage, selectedInquiry]);

  //select inquiry initially from list by default or from url param if mobile
  useEffect(() => {
    if (isMobile && inquiriesList?.length) {
      if (initialInquiryId) {
        const initialSelectedInquiry = inquiriesList.find(
          (inquiry: InquirySearchType) => inquiry.inquiryId === initialInquiryId,
        );

        if (initialSelectedInquiry) {
          setSelectedInquiry(initialSelectedInquiry);
        }
        return;
      }
    }
  }, [isMobile, inquiriesList, setSelectedInquiry, initialInquiryId]);

  function handleSearchValueChange(value: string) {
    setIsFetchingWithFilters(true);
    setSearchValue(value);
  }

  if (isFallbackVisible) {
    return (
      <DashboardFallback
        isFetching={isFetching}
        isNoCreatedIquiries={isNoCreatedIquiries}
        isError={isError}
      />
    );
  }

  return (
    <DashboardContext.Provider
      value={{
        selectedInquiry,
        searchItemTitle: selectedInquiry?.searchItem?.title,
        searchItemInitials: StringHelper.getInitialLetters(userInfo?.name || ""),
        searchItemImageUrl: ImageLogic.getMainImageUrl(selectedInquiry?.searchItem),
        currentUserName: userInfo?.name,
        currentUserInitials: StringHelper.getInitialLetters(userInfo?.name || ""),
        currentUserPhotoUrl: userInfo?.photo,
        clientName: selectedInquiry?.clientName,
        clientInitials: StringHelper.getInitialLetters(selectedInquiry?.clientName || ""),
        searchId: router.asPath,
      }}
    >
      <div className={styles.container} data-testid="dashboard">
        <DashboardLayout
          isFAQMobileOpen={isFAQMobileOpen}
          isFAQDesktopOpen={isFAQDesktopOpen}
          isInquirySelected={isInquirySelected}
          isLeftContainerCollapsed={isLeftContainerCollapsed}
          isRightContainerCollapsed={isRightContainerCollapsed}
          setSelectedInquiry={setSelectedInquiry}
          setInitialInquiryId={setInitialInquiryId}
          setIsFAQDesktopOpen={setIsFAQDesktopOpen}
          setIsFAQMobileOpen={setIsFAQMobileOpen}
          setIsLeftContainerCollapsed={setIsLeftContainerCollapsed}
          setIsRightContainerCollapsed={setIsRightContainerCollapsed}
          onClickContactSupport={onClickContactSupport}
          LeftContainer={
            <LeftContainer
              searchValue={searchValue}
              totalInquiriesAmount={totalRequestInquiriesAmount}
              inquiriesList={inquiriesList}
              eventsList={eventsList}
              initialInquiryId={initialInquiryId}
              isNoSearchMatchesInquiry={isNoSearchMatchesInquiry}
              isNoFiltersMatchesInquiry={isNoFiltersMatchesInquiry}
              isErrorBySearch={isErrorBySearch}
              isErrorByFiltering={isErrorByFiltering}
              isLeftContainerCollapsed={isLeftContainerCollapsed}
              appliedFilters={appliedFilters}
              paginationPagesAmount={paginationPagesAmount}
              paginationCurrentPage={paginationCurrentPage}
              setRequestInquiriesSkip={setRequestInquiriesSkip}
              handleNewPage={handleNewPage}
              isFetching={isFetchingWithFilters}
              setIsFetching={setIsFetching}
              setAppliedFilters={setAppliedFilters}
              setSelectedInquiry={setSelectedInquiry}
              setSearchValue={handleSearchValueChange}
              setIsURLWithQueryParams={setIsURLWithQueryParams}
              setIsLeftContainerCollapsed={setIsLeftContainerCollapsed}
            />
          }
          DashboardMobileManager={
            <DashboardMobileManager setIsFAQMobileOpen={setIsFAQMobileOpen} />
          }
          MainContainer={
            <MainContainer
              selectedInquiry={selectedInquiry}
              isFAQDesktopOpen={isFAQDesktopOpen}
              isLeftContainerCollapsed={isLeftContainerCollapsed}
              isRightContainerCollapsed={isRightContainerCollapsed}
              setIsLeftContainerCollapsed={setIsLeftContainerCollapsed}
              setIsRightContainerCollapsed={setIsRightContainerCollapsed}
              setSelectedInquiry={setSelectedInquiry}
              setInitialInquiryId={setInitialInquiryId}
            />
          }
          RightContainer={
            <RightContainer
              isRightContainerCollapsed={isRightContainerCollapsed}
              setIsRightContainerCollapsed={setIsRightContainerCollapsed}
              inquiry={selectedInquiry}
            />
          }
          DrawerFAQContainer={<DrawerFAQContainer setIsFAQMobileOpen={setIsFAQMobileOpen} />}
        />
      </div>
    </DashboardContext.Provider>
  );
}

export default Dashboard;
