import React, { MouseEvent } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { ListingCardV2 } from "@/src/component/partial/ListingCardV2";
import { Search } from "@/src/type/blaceV2/search/SearchType";
import styles from "./MarkerPreview.module.scss";

interface MarkerPreviewProps {
  onClick?: () => void;
  onClose: () => void;
  markerData: Search;
  markerAnchor: google.maps.marker.AdvancedMarkerElement;
}

function MarkerPreview({ markerData, markerAnchor, onClick, onClose }: MarkerPreviewProps) {
  const handleInfoWindowCloseClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <InfoWindow
      anchor={markerAnchor}
      pixelOffset={[0, 0]}
      headerDisabled
      className={styles.pinPreview}
    >
      <Box onClick={onClick} className={styles.previewContent}>
        <span
          className={styles.closeIconWrapper}
          onClick={handleInfoWindowCloseClick}
          data-testid="close-marker-preview-button"
        >
          <CloseIcon className={styles.closeIcon} color="action" />
        </span>
        <ListingCardV2 item={markerData} eager={true} isMapPreview />
      </Box>
    </InfoWindow>
  );
}

export default MarkerPreview;
