import {VendorType} from "@/src/type";
import {RegionDisplayValues, Regions, SearchType} from "@/src/type/blaceV2";
import {StringHelper} from "@/src/util/helper";

const cities = [
  { databaseValue: Regions.LA, displayValue: RegionDisplayValues.LA },
  { databaseValue: Regions.NY, displayValue: RegionDisplayValues.NY },
];

export function parseVendorsByPivotType(
  type: string,
  vendors: Array<VendorType.Vendor> = []
): Array<VendorType.Vendor> {
  return vendors?.filter(
    (item: VendorType.Vendor) => item.pivot?.type === type
  );
}

export function getVendorCategoryFromType(type?: string): string | undefined {
  if (!type) {
    return;
  }

  //@ts-ignore
  const category = VendorType.VendorCategory?.[type];
  if (typeof category === "string") {
    return category;
  }
  return StringHelper.capitalize(StringHelper.reverseCamelCase(type));
}

export function numberToCurrenyAbbrv(num: number = 0): string { //will be removed after replacing to numberToCurrencyAbbrv everywhere
  let counter = 0;
  let currency = "$";
  while (counter < num - 1) {
    currency += "$";
    counter++;
  }
  return currency;
}

export function formatVendorPricing(vendor: SearchType.SearchItem): string {
  // V2 data structure
  if (typeof vendor?.price?.priceLevel !== "undefined") {
    return numberToCurrenyAbbrv(vendor?.price?.priceLevel);
  }

  // V1 data structure
  return numberToCurrenyAbbrv((vendor.data as VendorType.Vendor)?.order_price_min);
}

export function getContactName(vendor: SearchType.SearchItem): string {
  // V1 data structure
  const vendorContact = (vendor.data as VendorType.Vendor)?.contact_name;
  // TODO: add V2 data structure usage, when it will be implemented
  return vendorContact || "";
}

export function getVendorAddressForDisplay(regions?: string[]): string {
  if (!regions?.length) {
    return "";
  }
  const cities = regions
    .map((region) => getCityByRegion(region))
    .filter((city) => city);
  const uniqueCities = new Set(cities);
  return Array.from(uniqueCities)
    .join(", ")
    .replace(/,(?=[^,]+$)/, " and");
}

export function getCityByRegion(region: string): string {
  const city = cities.find((city) => city.databaseValue === region);
  return city?.displayValue || "";
}
