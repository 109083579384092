import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Box, Typography } from "@mui/material";
import cn from "classnames";
import ConfettiExplosion from "react-confetti-explosion";
import { CheckRingSVG } from "@/src/asset/svg/CheckRingSVG";
import { ClosedRingSVG } from "@/src/asset/svg/ClosedRingSVG";
import { EmailIconSVG } from "@/src/asset/svg/EmailIconSVG";
import { FeedbackIconSVG } from "@/src/asset/svg/FeedbackIconSVG";
import { InquiryChangedSVG } from "@/src/asset/svg/InquiryChangedSVG";
import { BaseButton } from "@/src/component/base";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { LogProfileCircle } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/ChatLogMessage/components/LogProfileCircle";
import { EmailAttachments } from "@/src/component/view/Dashboard/component/MainContainer/components/ChatTabContent/component/EmailLogMessage/components/EmailAttachments";
import { AuthLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";
import {
  EmailAttachment,
  InquiryLog,
  InquiryLogEventTypes,
  InquiryLogSourceTypes,
  InquiryLogTitles,
} from "@/src/type/blaceV2";
import { FormattedDateHelper, StorageHelper } from "@/src/util/helper";
import styles from "./ChatLogMessage.module.scss";

interface ChatLogMessageProps {
  contentWithBorder: boolean;
  isNeedToShowFull?: boolean;
  isNeedToShowLess?: boolean;
  isFeedbackSubmitted?: boolean;
  handleShowFull?: () => void;
  handleShowLess?: () => void;
  inquiryMessage: InquiryLog;
  isFetchingFullInfo?: boolean;
  attachments?: EmailAttachment[];
}

function ChatLogMessage({
  contentWithBorder,
  inquiryMessage,
  isNeedToShowFull,
  isNeedToShowLess,
  isFetchingFullInfo,
  isFeedbackSubmitted,
  handleShowFull,
  handleShowLess,
  children,
  attachments,
}: PropsWithChildren<ChatLogMessageProps>) {
  const [explodeConfetti, setExplodeConfetti] = useState(false);

  const dashboardContext = useContext(DashboardContext);

  const isEmail = inquiryMessage.eventType == InquiryLogEventTypes.Email;
  const isBooked = inquiryMessage.eventType == BlaceV2Type.InquiryLogEventTypes.Booked;

  const isFromCurrentUser = inquiryMessage.userId === AuthLogic.getV2AuthUserId();
  const isFromClient = inquiryMessage.sourceType === InquiryLogSourceTypes.Client;
  const contentClasses = cn(styles.messageContent, {
    [styles.withBorder]: contentWithBorder,
  });
  // TODO : migrate to just `isFromCurrentUser` after BE fix (https://blace.atlassian.net/browse/B20-759)
  const wrapperClasses = cn(styles.messageWrapper, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : isFromClient,
    [styles.fullEmail]: isNeedToShowLess,
  });
  const messageClasses = cn(styles.message, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : isFromClient,
  });

  //@ts-ignore
  const innerClasses = cn(styles.inner, {
    [styles.fromRight]: isEmail ? isFromCurrentUser : isFromClient,
  });

  const emailTitle = `from ${isFromCurrentUser ? "me" : inquiryMessage.shortProperties.author}`;

  const renderCurrentTitleIcon = useMemo(() => {
    switch (inquiryMessage.eventType) {
      case InquiryLogEventTypes.Change:
        return <InquiryChangedSVG />;
      case InquiryLogEventTypes.Closed:
        return <ClosedRingSVG />;
      case InquiryLogEventTypes.Email:
        return <EmailIconSVG />;
      case InquiryLogEventTypes.NPS:
        return <FeedbackIconSVG/>;
      case InquiryLogEventTypes.Submit:
      case InquiryLogEventTypes.Reopen:
      case InquiryLogEventTypes.Booked:
        return (
          <Box className={cn(styles.titleIcon, styles.submitIconWrapper)}>
            <CheckRingSVG />
          </Box>
        );
      default:
        return "";
    }
  }, [inquiryMessage]);

  const renderCurrentTitle = useMemo(() => {
    switch (inquiryMessage.eventType) {
      case InquiryLogEventTypes.Submit:
        return InquiryLogTitles.inquirySubmitted;
      case InquiryLogEventTypes.Change:
        return InquiryLogTitles.inquiryChanged;
      case InquiryLogEventTypes.Closed:
        return InquiryLogTitles.inquiryClosed;
      case InquiryLogEventTypes.Reopen:
        return InquiryLogTitles.inquiryReopened;
      case InquiryLogEventTypes.Booked:
        return InquiryLogTitles.inquiryBooked;
      case InquiryLogEventTypes.NPS:
        return isFeedbackSubmitted ? InquiryLogTitles.inquiryFeedbackSubmitted : InquiryLogTitles.inquiryFeedbackNonSubmitted;
      case InquiryLogEventTypes.Email:
        return emailTitle;
      default:
        return "";
    }
  }, [inquiryMessage.eventType, isFeedbackSubmitted, emailTitle]);

  const shortLastUpdateDate = useMemo(() => {
    return FormattedDateHelper.formatTimestampToTime(inquiryMessage.createDate);
  }, [inquiryMessage.createDate]);

  const fullLastUpdateDate = useMemo(() => {
    return FormattedDateHelper.formatGlobalDate(inquiryMessage.createDate);
  }, [inquiryMessage.createDate]);

  useEffect(() => {
    (async () => {
      if (isBooked) {
        const key = `wasBookedConfettiExploded.${inquiryMessage.inquiryId}`;

        const wasBookedConfettiExploded = await StorageHelper().getItem(key);

        if (!wasBookedConfettiExploded) {
          StorageHelper().setItem(key, "true");
          setExplodeConfetti(true);
        }
      }
    })();
  }, [isBooked]);

  const handleConfettiComplete = () => {
    setExplodeConfetti(false);
  };

  const handleMessageClick = () => {
    isBooked && setExplodeConfetti(true);
  };

  const logProfileInitials = (isEmail && isFromCurrentUser)
    ? dashboardContext.currentUserInitials
    : isFromClient
      ? dashboardContext.clientInitials
      : dashboardContext.searchItemInitials;
  const logProfilePhoto = (isEmail && isFromCurrentUser)
    ? dashboardContext.currentUserPhotoUrl
    : isFromClient
      ? (isEmail ? undefined : dashboardContext.currentUserPhotoUrl)
      : dashboardContext.searchItemImageUrl;
  const logProfilePhotoAlt =
    ((isEmail && isFromCurrentUser)
      ? dashboardContext.currentUserName
      : isFromClient
        ? dashboardContext.clientName
        : dashboardContext.searchItemTitle) + " photo";

  return (
    <Box className={wrapperClasses} data-testid="chatLog">
      <div className={innerClasses}>
        <LogProfileCircle
          initials={logProfileInitials || ""}
          imageUrl={logProfilePhoto}
          imageAlt={logProfilePhotoAlt}
        />
        <div className={styles.messageBox} onClick={handleMessageClick}>
          <Box className={messageClasses}>
            <Box className={styles.messageTitle} data-testid="messageTitle">
              {renderCurrentTitleIcon}
              {renderCurrentTitle}
            </Box>
            {isEmail && (
              <>
                <Typography className={styles.email}>Email</Typography>
                <Typography className={styles.subject}>
                  {inquiryMessage.shortProperties.subject}
                </Typography>
              </>
            )}
            {explodeConfetti && (
              <div className={styles.confettiWrapper} data-testid="confettiWrapper">
                <ConfettiExplosion onComplete={handleConfettiComplete} force={0.8} zIndex={999} />
              </div>
            )}
            {!isFeedbackSubmitted && <Box className={contentClasses}>{children}</Box> }
            {isNeedToShowFull && (
              <BaseButton
                loading={isFetchingFullInfo}
                className={styles.showButton}
                onClick={handleShowFull}
              >
                Show Full
              </BaseButton>
            )}
            {isNeedToShowLess && (
              <BaseButton className={styles.showButton} onClick={handleShowLess} >
                Show Less
              </BaseButton>
            )}
            <EmailAttachments attachments={attachments} inquiryLogId={inquiryMessage.inquiryLogId} />
          </Box>
          <Typography className={styles.messageTime} data-testid="chatLogDate">
            <span className={styles.shortUpdatedDate}>
              {shortLastUpdateDate}
            </span>
            <span className={styles.fullUpdatedDate}>{fullLastUpdateDate}</span>
          </Typography>
        </div>
      </div>
    </Box>
  );
}

export default ChatLogMessage;
