import { ChangeEvent, KeyboardEvent, SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, FormControl, InputAdornment, TextField } from "@mui/material";
import { useRouter } from "next/router";
import { BaseButton } from "@/src/component/base";
import { useApp } from "@/src/component/provider";
import { KEYS } from "@/src/const";
import { ActivityLogic, SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { SharedConfigManager } from "@/src/util";
import styles from "./GlobalSearch.module.scss";

interface GlobalSearchProps {
  setIsFocused: (val: boolean) => void;
}

type SuggestionOptions = { title: string };

function GlobalSearch({
  setIsFocused,
}: GlobalSearchProps) {
  const app = useApp();
  const router = useRouter();
  const [searchValue, setSearchValue] = useState<string>("");
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const [canClearSearch, setCanClearSearch] = useState<boolean>(false);
  const [options, setOptions] = useState<SuggestionOptions[]>([]);
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  function trackSearchTerm(val?: string | null) {
    if (!val) {
      return;
    }
    ActivityLogic.ga4Tracking("search", {
      search_term: val,
    });
    ActivityLogic.toActivityService({
      action: "search",
      actionId: val,
      actionIdType: "searchValue",
      locationInApp: "GlobalSearch.tsx",
    });
  }

  function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.target.value ?? "");
  }

  function onAutoCompleteChangeHandler(
    _event: SyntheticEvent<Element, Event>,
    value: string | null,
  ) {
    app.setShowExtraFilters(false);
    app.setSearch({ searchTerm: value ?? "", searchQueryType: "simple" });
    setIsFocused(false);
    trackSearchTerm(value);
    setSearchValue(value ?? "");
  }

  function onKeyDownHandler(event?: KeyboardEvent<HTMLDivElement>) {
    if (event?.key === "Enter") {
      app.setSearch({ searchTerm: searchValue ?? "", searchQueryType: "full" });
      setIsFocused(false);
      trackSearchTerm(searchValue);
      setIsAutoCompleteOpen(false);
      setSearchValue(searchValue ?? "");
    }
  }

  const onClearSearch = () => {
    app.setSearch({ searchTerm: "", searchQueryType: "full" });
    setCanClearSearch(true);
    setIsFocused(false);
    trackSearchTerm("");
    setSearchValue("");
  };

  useEffect(() => {
    if (!app.searchTerm && canClearSearch) {
      setSearchValue("");
      setOptions([]);
      setCanClearSearch(false);
    }
  }, [app.searchTerm, canClearSearch]);

  useEffect(() => {
    if ((searchValue ?? "").length <= 1) {
      return;
    }

    const timeoutHolder = setTimeout(async () => {
      const response = await BlaceV2API.SearchServiceV2.postSearchSuggestion(
        SearchLogic.defaultSuggestions(searchValue),
      );

      if (Array.isArray(response.body?.payload?.value)) {
        const opts: SuggestionOptions[] = [];
        const noDups: string[] = [];
        for (const value of response.body?.payload?.value ?? []) {
          if (!noDups.includes(value["@search.text"])) {
            opts.push({ title: value["@search.text"] });
            noDups.push(value["@search.text"]);
          }
        }
        setOptions(opts);
      } else {
        setOptions([]);
      }
    }, 250);
    return () => clearTimeout(timeoutHolder);
  }, [searchValue]);

  useEffect(() => {
    // reset search filed value when user clicks on logo link
    if (!router.query.searchTerm) {
      setSearchValue("");
    }
  }, [router.query]);

  useEffect(() => {
    if (router.query.searchTerm) {
      // todo: rewrite logic to endecode the search string only before API call
      // & keep original search string in the url to avoid unnecessary decoding
      setSearchValue(atob(router.query.searchTerm.toString()));
    }
  }, []);

  return (
    <>
      <FormControl
        variant="standard"
        classes={{
          root: styles.globalSearchFieldGroup,
        }}
      >
        <Autocomplete
          id={"GlobalSearch.Autocomplete"}
          open={isAutoCompleteOpen}
          onOpen={() => setIsAutoCompleteOpen(true)}
          onClose={() => setIsAutoCompleteOpen(false)}
          disableClearable={true}
          options={options.map((option) => option.title)}
          disableCloseOnSelect={false}
          freeSolo={true}
          value={searchValue || ""}
          autoFocus={false}
          classes={{
            popper: styles.autoCompleteDropDownPopper,
          }}
          openOnFocus={false}
          onHighlightChange={() => setIsFocused(true)}
          onChange={onAutoCompleteChangeHandler}
          renderInput={(params) => (
            <TextField
              {...params}
              classes={{
                root: styles.globalSearchInput,
              }}
              autoFocus={false}
              onChange={onChangeHandler}
              hiddenLabel={true}
              fullWidth={true}
              placeholder="Search BLACE"
              onKeyDown={onKeyDownHandler}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment
                    className={
                      searchValue.length > 1
                        ? styles.globalSearchSearchIconActive
                        : styles.globalSearchSearchIconDefault
                    }
                    position="start"
                  >
                    <img
                      src={`${SVG_URL}/searchIconThin.svg`}
                      height="16"
                      width="16"
                      alt="search icon"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    {searchValue && (
                      <BaseButton className={styles.clearSearchButton} onClick={onClearSearch}>
                        <img
                          src={`${SVG_URL}/closeIconWhite.svg`}
                          height="16"
                          width="16"
                          alt="close icon"
                        />
                      </BaseButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </>
  );
}

export default GlobalSearch;
