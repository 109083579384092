import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import { useSearchItemFilesManager } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { SearchFileTypes, SearchType } from "@/src/type/blaceV2";
import { BaseButton } from "@src/component/base";
import styles from "./VendorButtonBar.module.scss";

interface VendorButtonBarProps {
  vendor: SearchType.SearchItem;
}

function VendorButtonBar({
  vendor,
}: VendorButtonBarProps) {
  const searchItemFilesManager = useSearchItemFilesManager();

  const hasTearSheet = typeof getTearSheetURL() === "string";

  if (!hasTearSheet) {
    return false;
  }

  // todo: migrate to useMemo (find the solution for `venue` and `venueMarketingAsset` changes tracking
  //  (without infinity re-renders)
  function getTearSheetURL() {
    // check V2 data structure
    if (SearchLogic.supportV2Files(vendor)) {
      return SearchLogic.buildFileUrl(
        searchItemFilesManager.getFile(vendor.files, SearchFileTypes.TearSheet),
      );
    }
  }

  function openTearSheat() {
    window.open(getTearSheetURL(), "_blank");
  }

  return (
    <div className={styles.detailSpecButtonBar}>
      {hasTearSheet && (
        <BaseButton
          type="submit"
          variant="outlined"
          size="large"
          onClick={openTearSheat}
        >
          <ViewKanbanOutlinedIcon />
          Tear Sheet
        </BaseButton>
      )}
    </div>
  );
}

export default VendorButtonBar;
